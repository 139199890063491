import React, { useReducer, useRef, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import styles from "./DatePickerModal.module.css";

interface DatePickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectDate: (date: string) => void;
}

const months: string[] = [
  "\u00A0",
  "فروردین",
  "اردیبهشت",
  "خرداد",
  "تیر",
  "مرداد",
  "شهریور",
  "مهر",
  "آبان",
  "آذر",
  "دی",
  "بهمن",
  "اسفند",
  "\u00A0",
];

const getDaysInMonth = (year: number, monthIndex: number): number =>
  new Date(year, monthIndex + 1, 0).getDate();

interface State {
  yearIndex: number;
  monthIndex: number;
  dayIndex: number;
}

const initialState: State = {
  yearIndex: 75, // سال 1375
  monthIndex: 1, // فروردین
  dayIndex: 1, // روز اول
};

type Action =
  | { type: "SET_YEAR"; payload: number }
  | { type: "SET_MONTH"; payload: number }
  | { type: "SET_DAY"; payload: number };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_YEAR": {
      const daysInMonth = getDaysInMonth(1300 + action.payload, state.monthIndex);
      return {
        ...state,
        yearIndex: action.payload,
        dayIndex: Math.min(state.dayIndex, daysInMonth),
      };
    }
    case "SET_MONTH": {
      const daysInMonth = getDaysInMonth(1300 + state.yearIndex, action.payload);
      return {
        ...state,
        monthIndex: action.payload,
        dayIndex: Math.min(state.dayIndex, daysInMonth),
      };
    }
    case "SET_DAY":
      return { ...state, dayIndex: action.payload };
    default:
      return state;
  }
};

const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const StyledDiv = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => <div ref={ref} {...props} />
);
StyledDiv.displayName = "StyledDiv";

const AnimatedDiv = animated(StyledDiv);

const DatePickerModal: React.FC<DatePickerModalProps> = ({ isOpen, onClose, onSelectDate }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const years: number[] = Array.from({ length: 150 }, (_, i) => 1300 + i).concat([-1, -1]);
  const days: number[] = [-1]
    .concat(
      Array.from(
        { length: getDaysInMonth(1300 + state.yearIndex, state.monthIndex) },
        (_, i) => i + 1
      )
    )
    .concat(-1);

  const yearRef = useRef<HTMLDivElement | null>(null);
  const monthRef = useRef<HTMLDivElement | null>(null);
  const dayRef = useRef<HTMLDivElement | null>(null);

  const scrollStyles = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0%)" : "translateY(100%)",
    config: { tension: 170, friction: 26 },
  });

  const scrollToSelected = (ref: React.RefObject<HTMLDivElement | null>, index: number) => {
    if (ref.current && index >= 0) {
      const offset = 56;
      const centerIndex = 1;
      ref.current.scrollTo({
        top: (index - centerIndex) * offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        scrollToSelected(yearRef, state.yearIndex);
        scrollToSelected(monthRef, state.monthIndex);
        scrollToSelected(dayRef, state.dayIndex);
      }, 0);
    }
  }, [isOpen, state.yearIndex, state.monthIndex, state.dayIndex]);

  const handleScroll = debounce(
    (ref: React.RefObject<HTMLDivElement>, type: Action["type"], itemHeight: number) => {
      if (ref.current) {
        const index = Math.round(ref.current.scrollTop / itemHeight) + 1;
        dispatch({ type, payload: index });
      }
    },
    100
  );

  const handleConfirm = () => {
    // بررسی معتبر بودن سال، ماه و روز
    if (
      state.yearIndex === -1 ||
      state.monthIndex === -1 ||
      state.dayIndex === -1
    ) {
      alert("لطفاً یک تاریخ معتبر انتخاب کنید.");
      return;
    }

    // تبدیل به فرمت yyyy-MM-dd
    const formattedDate = `${1300 + state.yearIndex}-${String(state.monthIndex).padStart(2, "0")}-${String(state.dayIndex).padStart(2, "0")}`;

    // ارسال تاریخ به تابع والد
    onSelectDate(formattedDate);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <AnimatedDiv
        style={scrollStyles}
        className={styles.modalContent}
        role="dialog"
        aria-labelledby="modalTitle"
      >
        <div className={styles.modalHeader}>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
          <h2 id="modalTitle" className={styles.modalTitle}>تاریخ تولد</h2>
        </div>

        <div className={styles.dateSelector}>
          <div className={styles.columnWrapper}>
            <div className={styles.fixedLine}></div>
            <div className={styles.fixedLineBottom}></div>
            <div
              ref={yearRef}
              className={styles.column}
              onScroll={() => handleScroll(yearRef, "SET_YEAR", 56)}
            >
              {years.map((year, index) => (
                <div key={index} className={styles.dateItem}>
                  {year !== -1 ? year : ""}
                </div>
              ))}
            </div>
          </div>

          <div className={styles.columnWrapper}>
            <div className={styles.fixedLine}></div>
            <div className={styles.fixedLineBottom}></div>
            <div
              ref={monthRef}
              className={styles.column}
              onScroll={() => handleScroll(monthRef, "SET_MONTH", 56)}
            >
              {months.map((month, index) => (
                <div key={index} className={styles.dateItem}>
                  {month !== "" ? month : ""}
                </div>
              ))}
            </div>
          </div>

          <div className={styles.columnWrapper}>
            <div className={styles.fixedLine}></div>
            <div className={styles.fixedLineBottom}></div>
            <div
              ref={dayRef}
              className={styles.column}
              onScroll={() => handleScroll(dayRef, "SET_DAY", 56)}
            >
              {days.map((day, index) => (
                <div key={index} className={styles.dateItem}>
                  {day !== -1 ? day.toString().padStart(2, "0") : "\u00A0"}
                </div>
              ))}
            </div>
          </div>
        </div>

        <button className={styles.confirmButton} onClick={handleConfirm}>
          ثبت تاریخ تولد
        </button>
      </AnimatedDiv>
    </div>
  );
};

export default DatePickerModal;
