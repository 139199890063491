import React from "react";
import { useNavigate } from "react-router-dom"; // برای هدایت کاربر
import styles from "./ServiceList.module.css";
import ArrowRightIcon from "../assets/icons/arrowRight.svg";
import PolygonIcon from "../assets/icons/Polygon.svg";

interface Service {
  id: number;
  name: string;
  logo: string;
  description: string;
}

const ServiceCard: React.FC<{ service: Service }> = React.memo(({ service }) => {
  const navigate = useNavigate();

  const handleReserveClick = () => {
    navigate(`/times?service=${service.id}`); // هدایت به صفحه TimesPage با id سرویس
  };

  return (
    <div key={service.id} className={styles.serviceCard}>
      <div className={styles.serviceRow}>
        <div className={styles.serviceInfo}>
          <div className={styles.serviceHeader}>
            <h6 className={styles.serviceName}>{service.name || "نامشخص"}</h6>
            <img
              src={PolygonIcon}
              alt="Polygon Icon"
              className={styles.servicePolygonIcon}
            />
          </div>
          <p className={styles.serviceDetails}>
            {service.description || "توضیحاتی موجود نیست"}
          </p>
        </div>

        <div className={styles.serviceIconContainer}>
          <img
            src={service.logo || "https://via.placeholder.com/50"} // مقدار پیش‌فرض برای تصویر
            alt={service.name || "بدون نام"}
            className={styles.serviceIcon}
          />
        </div>
      </div>

      <div className={styles.reserveButtonContainer}>
        <button className={styles.reserveButton} onClick={handleReserveClick}>
          <img
            src={ArrowRightIcon}
            alt="Arrow Right"
            className={styles.reserveArrowIcon}
          />
          <span>رزرو نوبت</span>
        </button>
      </div>
    </div>
  );
});

export default ServiceCard;
