import React from "react";
import { useNavigate } from "react-router-dom"; // برای هدایت به صفحه دیگر
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ID } from "../../assets/icons/ID.svg";
import { ReactComponent as Wallet } from "../../assets/icons/Wallet.svg";
import { ReactComponent as ProfCalendar } from "../../assets/icons/ProfCalendar.svg";
import styles from "./ProfileMenuList.module.css";

interface MenuItem {
  id: number;
  title: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  path: string; // مسیر صفحه مورد نظر
}

const menuItems: MenuItem[] = [
  { id: 1, title: "اطلاعات شخصی", icon: ID, path: "/profile/info" },
  { id: 2, title: "تاریخچه تراکنش‌ها", icon: Wallet, path: "/profile/transactions" },
  { id: 3, title: "تاریخچه وقت‌های رزرو شده", icon: ProfCalendar, path: "/profile/BookingHistory" },
];

const ProfileMenuList: React.FC = () => {
  const navigate = useNavigate(); // Hook برای هدایت کاربر به صفحه دیگر

  const handleNavigation = (path: string) => {
    navigate(path); // هدایت به مسیر مشخص‌شده
  };

  return (
    <>
      <div className={styles.menuListContainer}>
        {menuItems.map((item) => (
          <div
            key={item.id}
            className={styles.menuItem}
            onClick={() => handleNavigation(item.path)} // رویداد کلیک برای هدایت
            style={{ cursor: "pointer" }} // نشانگر موس به حالت کلیک
          >
            <div className={styles.chevronContainer}>
              <ArrowLeftIcon className={styles.chevronIcon} />
            </div>
            <p className={styles.menuText}>{item.title}</p>
            <div className={styles.iconContainer}>
              <item.icon className={styles.menuIcon} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.divider}></div>
    </>
  );
};

export default ProfileMenuList;
