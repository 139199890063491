import React, { useState, useMemo, useTransition } from "react";
import { utils, Day } from "react-modern-calendar-datepicker";
import "./DateNavigation.css";

interface MonthDayInfo {
  date: Day;
  dayOfWeek: string;
  isToday: boolean;
  isSelected: boolean;
}

interface DateNavigationProps {
  onDateChange: (selectedDate: Day) => void;
}

const DateNavigation: React.FC<DateNavigationProps> = ({ onDateChange }) => {
  const today = useMemo(() => utils("fa").getToday(), []);
  const daysOfWeek = useMemo(() => ["شنبه", "یک‌شنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنج‌شنبه", "جمعه"], []);

  const generateMonthDays = (referenceDate: Day): MonthDayInfo[] => {
    const endOfMonth = new Date(referenceDate.year, referenceDate.month, 0);
    const daysInMonth = endOfMonth.getDate();

    return Array.from({ length: daysInMonth }, (_, dayOffset) => {
      const offsetDate = new Date(referenceDate.year, referenceDate.month - 1, dayOffset + 1);
      const date: Day = {
        year: offsetDate.getFullYear(),
        month: offsetDate.getMonth() + 1,
        day: offsetDate.getDate(),
      };
      const dayOfWeek = daysOfWeek[offsetDate.getDay()];

      return {
        date,
        dayOfWeek,
        isToday: date.day === today.day && date.month === today.month && date.year === today.year,
        isSelected: false,
      };
    })
      .filter((dayInfo) =>
        dayInfo.date.year > today.year ||
        (dayInfo.date.year === today.year && dayInfo.date.month > today.month) ||
        (dayInfo.date.year === today.year && dayInfo.date.month === today.month && dayInfo.date.day >= today.day)
      )
      .map((dayInfo) => {
        if (
          dayInfo.date.day === today.day &&
          dayInfo.date.month === today.month &&
          dayInfo.date.year === today.year
        ) {
          dayInfo.isSelected = true;
        }
        return dayInfo;
      });
  };

  const [state, setState] = useState(() => ({
    selectedDate: today,
    monthDays: generateMonthDays(today),
  }));

  const [isPending, startTransition] = useTransition();

  const handleDateSelect = (date: Day) => {
    startTransition(() => {
      setState((prevState) => ({
        ...prevState,
        selectedDate: date,
        monthDays: prevState.monthDays.map((dayInfo) => ({
          ...dayInfo,
          isSelected:
            dayInfo.date.year === date.year &&
            dayInfo.date.month === date.month &&
            dayInfo.date.day === date.day,
        })),
      }));
    });

    onDateChange(date);
  };

  const handleMonthChange = (increment: number) => {
    const newMonth = state.selectedDate.month + increment;
    if (newMonth >= 1 && newMonth <= 12) {
      const newDate = { ...state.selectedDate, month: newMonth };
      setState({
        selectedDate: newDate,
        monthDays: generateMonthDays(newDate),
      });
    }
  };

  return (
    <div className="date-navigation-wrapper">
      <div className="date-navigation-header">
        <button
          className="month-button"
          onClick={() => handleMonthChange(-1)}
          disabled={state.selectedDate.month === today.month && state.selectedDate.year === today.year}
        >
          ❮ ماه قبل
        </button>

        <h6 className="current-month">
          {utils("fa").getMonthName(state.selectedDate.month)} {state.selectedDate.year}
        </h6>

        <button
          className="month-button"
          onClick={() => handleMonthChange(1)}
          disabled={state.selectedDate.month === 12}
        >
          ماه بعد ❯
        </button>
      </div>

      <div className="date-navigation-days">
        {state.monthDays.map((dayInfo) => (
          <div
            key={`${dayInfo.date.year}-${dayInfo.date.month}-${dayInfo.date.day}`}
            className={`day-item ${dayInfo.isSelected ? "selected" : dayInfo.isToday ? "active" : ""} ${isPending ? "loading" : ""}`}
            onClick={() => handleDateSelect(dayInfo.date)}
          >
            <div className="day-name">{dayInfo.dayOfWeek}</div>
            <div className="day-number">{dayInfo.date.day}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(DateNavigation);