import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SplashPage from "./pages/splash-page";
import ServiceDetailsPage from "./pages/service-details";
import HelpPage from "./pages/help-page"; // تغییر MainPage به HelpPage
import MobileVerifyPage from "./pages/mobile-verify-page";
import MobileOtpPage from "./pages/mobile-otp-page";
import MainPage from "./pages/main-page";
import TimesPage from "./pages/times-page";
import ThemeColorHandler from "./core/ThemeColorHandler";
import ServiceList from "./pages/Service-List"; // اضافه کردن ServiceListPage
import DresserList from "./pages/Dresser-List"; // اضافه کردن ServiceListPage
import Profile from "./pages/Profile";
import InfoPage from "./pages/InfoPage";
import Transactions from "./components/Profile/transactions/transactions";
import BookingHistory from "./components/Profile/BookingHistory/BookingHistory";
import Clarity from '@microsoft/clarity';
//import { clarity } from 'react-microsoft-clarity';
import {AppConsts} from "./core/AppConsts";
// import PortraitHandler from "./core/PortraitHandler"; 
// import InstallerHandler from "./core/InstallerHandler";
// import BlockNavigationHandler from "./core/BlockNavigationHandler";

const App: React.FC = () => {

  Clarity.init(AppConsts.projectId);
  Clarity.setTag('tenant', 'Default');
  return (
    <Router>
        {/* <PortraitHandler /> ✅ کنترل چرخش صفحه
        <InstallerHandler /> ✅ مدیریت نصب اپلیکیشن
        <BlockNavigationHandler /> ✅ جلوگیری از بازگشت به صفحات قبلی */}
      <ThemeColorHandler />
      <Routes>
        <Route path="/" element={<SplashPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/mobile-verify" element={<MobileVerifyPage />} />
        <Route path="/verify-otp" element={<MobileOtpPage />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/times" element={<TimesPage />} />
        <Route path="/service-details" element={<ServiceDetailsPage />} />
        <Route path="/ServiceList" element={<ServiceList />} />
        <Route path="/DresserList" element={<DresserList />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/profile/info" element={<InfoPage />} />
        <Route path="/profile/transactions" element={<Transactions />} />
        <Route path="/profile/BookingHistory" element={<BookingHistory />} />
      </Routes>
    </Router>
  );
};

export default App;
