import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreService } from '../services/store-service'; // استفاده از StoreService

interface TabState {
  activeTab: 'home' | 'appointments' | 'profile';
  selectedService: string | null;
  selectedStylist: string | null; 
}

const getDefaultTab = (): 'home' | 'appointments' | 'profile' => {
  const path = window.location.pathname; 
  if (path.includes('/profile')) return 'profile';
  if (path.includes('/times')) return 'appointments';
  return 'home'; 
};

const initialState: TabState = {
  activeTab: (StoreService.GetData('activeTab') as 'home' | 'appointments' | 'profile') || getDefaultTab(),
  selectedService: null,
  selectedStylist: null, 
};

const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<'home' | 'appointments' | 'profile'>) {
      state.activeTab = action.payload;
      StoreService.SetData('activeTab', action.payload); // ذخیره activeTab در StoreService
    },
    setSelectedService(state, action: PayloadAction<string | null>) {
      state.selectedService = action.payload;
    },
    setSelectedStylist(state, action: PayloadAction<string | null>) {
      state.selectedStylist = action.payload;
    },
  },
});

export const { setActiveTab, setSelectedService, setSelectedStylist } = tabSlice.actions;
export default tabSlice.reducer;
