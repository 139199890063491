import {
  BusinessServiceProxy,
  IdentityServiceProxy,
  TokenAuthServiceProxy,
  AuthenticateResultModel,
  MobileBusinessProfileDto,
  MobilePartnersDto,
  MobileReservedTimeDto,
  MobileServiceProxy,
  MobileCustomerProfileDto,
  CustomerServiceProxy, // ✅ اضافه کردن CustomerServiceProxy

} from "./shared/service-proxies/service-proxies";
import { AppConsts } from "../core/AppConsts";
import { AxiosConfigure } from "../core/AxiosConfigure";
import { StoreService } from "../services/store-service"; // استفاده از StoreService

export class BusinessService {
  private _businessServiceProxy: BusinessServiceProxy;
  private _identityServiceProxy: IdentityServiceProxy;
  private _tokenAuthServiceProxy: TokenAuthServiceProxy;
  private _mobileServiceProxy: MobileServiceProxy; // ✅ اضافه کردن MobileServiceProxy
  private _customerServiceProxy: CustomerServiceProxy;


  constructor() {
    this._businessServiceProxy = new BusinessServiceProxy(
      AppConsts.appBaseUrl,
      AxiosConfigure.getInstance()
    );
    this._mobileServiceProxy = new MobileServiceProxy(
      AppConsts.appBaseUrl,
      AxiosConfigure.getInstance()
    );
    this._identityServiceProxy = new IdentityServiceProxy(
      AppConsts.appBaseUrl,
      AxiosConfigure.getInstance()
    );
    this._tokenAuthServiceProxy = new TokenAuthServiceProxy(
      AppConsts.appBaseUrl,
      AxiosConfigure.getInstance()
    );
    this._customerServiceProxy = new CustomerServiceProxy(
      AppConsts.appBaseUrl,
      AxiosConfigure.getInstance()
    );
  }



  /**
   * ✅ دریافت پروفایل اختصاصی کاربر
   * @returns اطلاعات پروفایل کاربر
   */
  getUserProfile(): Promise<MobileCustomerProfileDto> {
    return this._mobileServiceProxy
      .customerGet()
      .then((profile) => {
        StoreService.SetData("user-profile", profile); // ذخیره در localStorage
        return profile;
      })
      .catch((error) => {
        console.error("❌ خطا در دریافت پروفایل کاربر:", error);
        throw error;
      });
  }


  /**
   * دریافت پروفایل بر اساس نام
   * @param name نام پروفایل
   * @returns داده‌های پروفایل
   */
  getProfile(name: string): Promise<MobileBusinessProfileDto> {
    return this._businessServiceProxy.profile(name);
  }
  getFile(fileId: any, accessKey: any) : Promise<string> {
    return this._businessServiceProxy.picture(fileId,accessKey);
  }


  /**
   * دریافت لیست شرکا
   * @param cancelToken توکن لغو درخواست
   * @returns لیست شرکا
   */
  getPartners(cancelToken?: any): Promise<MobilePartnersDto[]> {
    return this._businessServiceProxy.partners(cancelToken);
  }

  /**
   * دریافت زمان‌های رزروشده
   * @param cancelToken توکن لغو درخواست
   * @returns لیست زمان‌های رزروشده
   */
  getReservedTimes(cancelToken?: any): Promise<MobileReservedTimeDto[]> {
    return this._businessServiceProxy.reservedTimes(cancelToken);
  }

  /**
   * دریافت اطلاعات اصلی
   * @param cancelToken توکن لغو درخواست
   * @returns void
   */
  getMain(cancelToken?: any): Promise<void> {
    return this._businessServiceProxy.main(cancelToken);
  }

  /**
   * دریافت تصویر با شناسه و توکن دسترسی
   * @param id شناسه تصویر
   * @param accessToken توکن دسترسی
   * @returns تصویر به صورت Base64
   */
  getPicture(id: string, accessToken: string): Promise<string> {
    return this._businessServiceProxy.picture(id, accessToken);
  }

  /**
   * ارسال OTP به شماره موبایل
   * @param mobile شماره موبایل
   * @param accessToken توکن دسترسی
   * @returns نتیجه ارسال OTP
   */
  sendOtp(mobile: string, accessToken: string): Promise<any> {
    return this._identityServiceProxy.sendOtp(mobile, accessToken);
  }

  /**
   * تایید OTP
   * @param otp کد OTP
   * @param accessToken توکن دسترسی
   * @returns نتیجه تایید OTP
   */
  verifyOtp(otp: number, accessToken: string): Promise<string> {
    return this._identityServiceProxy.verifyOtp(otp, accessToken);
  }

  /**
   * احراز هویت موبایل
   * @param mobile شماره موبایل
   * @param token توکن دسترسی تایید شده
   * @returns نتیجه احراز هویت
   */
  authenticateMobile(
    mobile: string,
    token: string
  ): Promise<AuthenticateResultModel> {
    if (!mobile || !token) {
      return Promise.reject(new Error("Mobile number and token are required."));
    }

    return this._tokenAuthServiceProxy
      .authenticateMobile(mobile, token)
      .then((result) => {
        if (result?.accessToken) {
          // ذخیره accessToken جدید در StoreService
          StoreService.SetData("accessKey", result.accessToken);
          console.log("Access token stored in StoreService.");
        }
        return result;
      });
  }
}
