import React from "react";
import { ReactComponent as SupportIcon } from "../../assets/icons/Support.svg";
import styles from "./SupportSection.module.css";

// تعریف `props` برای دریافت `profile`
interface SupportSectionProps {
  profile: any;
}

const SupportSection: React.FC<SupportSectionProps> = ({ profile }) => {
  const handleSupportClick = () => {
    if (profile?.phone) {
      window.location.href = `tel:${profile.phone}`;
    } else {
      alert("شماره تماس پشتیبانی موجود نیست.");
    }
  };

  return (
    <>
      <div className={styles.supportContainer}>
        <button
          className={styles.supportButton}
          onClick={handleSupportClick}
          disabled={!profile?.phone} // غیرفعال کردن دکمه اگر شماره تماس موجود نباشد
        >
          <span className={styles.supportText}>پشتیبانی</span>
          <span className={styles.supportIcon}>
            <SupportIcon className={styles.iconSVG} />
          </span>
        </button>
      </div>
      <div className={styles.divider}></div>
    </>
  );
};

export default SupportSection;
