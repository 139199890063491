import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreService } from "../services/store-service"; // دریافت داده از localStorage
import Layout from "../components/Layout/Layout";
import styles from "./DresserList.module.css";
import ArrowRightIcon from "../assets/icons/arrowRight.svg";
import DresserCard from "./DresserCard"; // ایمپورت کامپوننت DresserCard

interface Dresser {
  id: number;
  name: string;
  service: string;
  date: string;
  avatar: string;
  icon?: string;
}

const DresserList: React.FC = () => {
  const navigate = useNavigate();
  const [hairdressers, setHairdressers] = useState<Dresser[]>([]);

  // دریافت داده‌ها از localStorage هنگام بارگذاری
  useEffect(() => {
    const storedPartners = StoreService.GetData("partners");
    if (storedPartners) {
      const formattedHairdressers = storedPartners.map((dresser: any) => ({
        id: dresser.id,
        name: `${dresser.firstname || ""} ${dresser.lastname || ""}`.trim() || "نامشخص", // ترکیب firstname و lastname
        service: dresser.description || "بدون سرویس", // توضیحات بجای `title`
        date: dresser.availability || "نامشخص", // زمان در دسترس بودن
        avatar: dresser.logo || "https://via.placeholder.com/50", // مقدار پیش‌فرض برای تصویر
        icon: dresser.icon || ArrowRightIcon, // آیکون پیش‌فرض
      }));
      setHairdressers(formattedHairdressers);
    }
  }, []);

  return (
    <Layout>
      <div className={styles.dresserListPage}>
        {/* هدر */}
        <div className={styles.header}>
          <h6 className={styles.title}>آرایشگرها</h6>
          <button
            className={styles.backButton}
            onClick={() => navigate("/main")} // بازگشت به صفحه اصلی
          >
            <img src={ArrowRightIcon} alt="Back to Main" />
          </button>
        </div>

        {/* لیست آرایشگرها */}
        <div className={styles.listContent}>
          {hairdressers.map((dresser) => (
            <DresserCard key={dresser.id} dresser={dresser} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default DresserList;
