import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import SecondPageTopSection from "../components/TopSection/SecondPageTopSection";
import ContactSection from "../components/ContactSection/ContactSection";
import { BusinessService } from "../services/business-service";
import { StoreService } from "../services/store-service";
import { getCategories } from "../services/server-actions"; // ✅ اضافه کردن getCategories
import BgImage from "../assets/Bg.webp";
import DefaultLogo from "../assets/images/logo.png";
import { MobileBusinessProfileDto } from "../services/shared/service-proxies/service-proxies";

const MobileVerifyPage: React.FC = () => {
  const navigate = useNavigate();
  const businessService = useMemo(() => new BusinessService(), []);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [profile, setProfile] = useState<MobileBusinessProfileDto>(new MobileBusinessProfileDto());
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const businessProfile = StoreService.GetData("business-profile");
  const logo = businessProfile?.logo || DefaultLogo;
  const title = businessProfile?.name || "سالن زیبایی میناراد";
  const description = profile?.slogan || "لاین زیبایی";

  // دریافت داده‌های پروفایل و دسترسی توکن
  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResult = await businessService.getProfile("Default");
        setProfile(profileResult);
        const token = StoreService.GetData("accessKey");
        if (token) setAccessToken(token);
      } catch (error) {
        console.error("Error fetching profile or access token:", error);
        alert("خطا در دریافت اطلاعات پروفایل یا توکن دسترسی");
      }
    };

    fetchData();
  }, [businessService]);

  // دریافت دسته‌بندی‌ها بعد از به‌روزرسانی accessToken
  useEffect(() => {
    const fetchCategories = async () => {
      if (accessToken) {
        try {
          const categories = StoreService.GetData("categories");
          if (!categories) {
            const fetchedCategories = await getCategories(accessToken);
            StoreService.SetData("categories", fetchedCategories);
          }
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      }
    };

    fetchCategories();
  }, [accessToken]); // ✅ وابستگی به accessToken

  const handlePhoneInputChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handleContinue = async () => {
    if (!phoneNumber) {
      alert("شماره تلفن را وارد کنید.");
      return;
    }

    try {
      let accessToken = "";
      if (profile.accessKey != null) accessToken = profile.accessKey;
      const result = await businessService.sendOtp(phoneNumber, accessToken);
      console.log("Send OTP Result:", result);

      navigate("/verify-otp", {
        state: { mobile: phoneNumber, token: result.token, ttl: result.ttl },
      });
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("خطایی در ارسال OTP رخ داد.");
    }
  };

  return (
    <Layout>
      <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <div style={{ flex: "0 0 55%", position: "relative", zIndex: 1 }}>
          <SecondPageTopSection
            backgroundImage={BgImage}
            logoImage={logo}
            title={title}
            description={description}
            customStyles={{ container: { height: "100%" } }}
          />
        </div>
        <div
          style={{
            flex: 1,
            backgroundColor: "#FFFFFF",
            borderRadius: "50px 50px 0 0",
            marginTop: "-40px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <p
            style={{
              color: "#555",
              marginBottom: "10px",
              fontSize: "16px",
              textAlign: "center",
              width: "100%",
            }}
          >
            لطفا برای ادامه شماره تلفن خود را وارد کنید.
          </p>
          <input
            type="tel"
            inputMode="numeric"
            placeholder="09*********"
            className="form-control"
            style={{
              width: "90%",
              height: "50px",
              textAlign: "center",
              fontSize: "16px",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
            value={phoneNumber}
            onChange={(e) => handlePhoneInputChange(e.target.value)}
          />
          <button
            className="btn btn-primary"
            style={{
              width: "90%",
              height: "50px",
              borderRadius: "24px",
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: "#695CD5",
            }}
            onClick={handleContinue}
          >
            ادامه
          </button>
          <hr
            style={{
              margin: "24px auto",
              width: "70%",
              borderColor: "#ccc",
              opacity: 0.6,
            }}
          />
          <ContactSection />
        </div>
      </div>
    </Layout>
  );
};

export default MobileVerifyPage;
