"use client";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ReservedTimes.module.css";
import defaultImage from "../../assets/images/freeface.jpg";
import { ReactComponent as CartIcon } from "../../assets/icons/CartIcon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import dayjs from "dayjs";
import "dayjs/locale/fa";
import ClockIcon from "../../assets/icons/ClockIcon.svg";
import { getCustomerTimes } from "../../services/server-actions";
import { MobileCustomerProfileTimeDto } from "../../services/shared/service-proxies/service-proxies"; // MobileCustomerProfileTimeDto فقط برای مشخص کردن type استفاده شده

dayjs.locale("fa");

const ReservedTimes: React.FC = () => {
  const [reservations, setReservations] = useState<MobileCustomerProfileTimeDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchReservations() {
      try {
        setLoading(true);
        const times = await getCustomerTimes();
        setReservations(times);
      } catch (error) {
        console.error("❌ خطا در دریافت تایم‌های کاربر:", error);
        setReservations([]); // مقدار را پاک کنیم تا UI درست نمایش داده شود
      } finally {
        setLoading(false);
      }
    }
  
    fetchReservations();
  }, []);
  
  if (loading) {
    return <p></p>;
  }

  if (reservations.length === 0) {
    return null; // ✅ اگر هیچ رزروی وجود ندارد، کل کامپوننت نمایش داده نشود
  }

  return (
    <div>
      <h6 className={styles.title}>وقت‌های رزرو شده شما</h6>
      <div className={styles.horizontalScroll}>
        {reservations.map((reservation) => (
          <div key={`${reservation.partnerId}-${reservation.onDate}`} className={styles.card}>
            <div className={styles.cardHeader}>
              <span className={styles.statusCircle}>✔</span>
              <span className={styles.statusText}>رزرو شده</span>
              <Link to={`/details/${reservation.partnerId}`} className={styles.detailsText}>
                <span className={styles.detailsLink}>جزئیات</span>
                <ArrowIcon className={styles.arrowIcon} />
              </Link>
            </div>

            <div className={styles.cardBody}>
              <img src={defaultImage} alt="آرایشگر" className={styles.stylistImage} />
              <div className={styles.textContainer}>
                <h6 className={styles.serviceTitle}>خدمات عمومی</h6>
                <p className={styles.stylistName}>آرایشگر ناشناس</p>
              </div>
            </div>

            <div className={styles.cardFooter}>
              <div className={styles.timeContainer}>
                <CartIcon className={styles.timeIcon} />
                <span className={styles.timeText}>
                  {/* ✅ استفاده از luxon برای فرمت صحیح تاریخ */}
                  {reservation.onDate ? reservation.onDate.toFormat("yyyy/MM/dd") : "نامشخص"}
                </span>
              </div>
              <div className={styles.timeContainer}>
                <span className={styles.timeIcon}>
                  <img src={ClockIcon} alt="Clock Icon" />
                </span>
                <span className={styles.timeText}>
                  {reservation.fromTime} تا {reservation.toTime}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ReservedTimes;
