import { configureStore } from "@reduxjs/toolkit";
import tabReducer from "./tabSlice";


const store = configureStore({
  reducer: {
    tab: tabReducer,

  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
