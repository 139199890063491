import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import SecondPageTopSection from "../components/TopSection/SecondPageTopSection";
import { BusinessService } from "../services/business-service"; // ✅ استفاده از BusinessService
import { StoreService } from "../services/store-service";
import { getCategories } from "../services/server-actions"; // ✅ اضافه کردن getCategories
import BgImage from "../assets/Bg.webp";
import DefaultLogo from "../assets/images/logo.png";

const MobileOtpPage: React.FC = () => {
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // ✅ مدیریت لودینگ
  const navigate = useNavigate();

  // دریافت اطلاعات از صفحه قبل (Mobile-Verify)
  const location = useLocation();
  const { mobile, token, ttl, accessToken } = location.state || {};

  const businessService = new BusinessService();

  // دریافت لوگو و اطلاعات سالن از StoreService
  const businessProfile = StoreService.GetData("business-profile");
  const logo = businessProfile?.logo || DefaultLogo;
  const title = businessProfile?.name || "سالن زیبایی میناراد";
  const description = businessProfile?.slogan || "لاین زیبایی";

  // مدیریت تغییر مقدار کد ورودی
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  // ارسال مجدد OTP
  const handleResendCode = async () => {
    try {
      const storedAccessToken = StoreService.GetData("accessKey") || accessToken;
      if (!mobile || !storedAccessToken) {
        alert("اطلاعات لازم برای ارسال مجدد کد موجود نیست.");
        return;
      }
      setLoading(true);
      const result = await businessService.sendOtp(mobile, storedAccessToken);
      console.log("Resend OTP Result:", result);
      alert("کد مجدداً ارسال شد.");
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("خطایی در ارسال مجدد کد رخ داد.");
    } finally {
      setLoading(false);
    }
  };

  // مدیریت ادامه فرآیند پس از تأیید OTP
  const handleContinue = async () => {
    if (!code) {
      alert("لطفاً کد تایید را وارد کنید.");
      return;
    }

    try {
      if (!token) {
        alert("توکن برای تایید موجود نیست.");
        return;
      }

      setLoading(true);

      // 1️⃣ تایید OTP
      const verifyResult = await businessService.verifyOtp(parseInt(code, 10), token);
      console.log("Verify OTP Result:", verifyResult);

      if (!verifyResult) {
        alert("تایید کد ناموفق بود.");
        return;
      }

      // 2️⃣ احراز هویت موبایل
      const authResult = await businessService.authenticateMobile(mobile, verifyResult);
      console.log("Authenticate Result:", authResult);

      if (authResult.accessToken) {
        StoreService.SetData("user", authResult); // ✅ ذخیره اطلاعات کاربر
        StoreService.SetData("accessKey", authResult.accessToken);

        // 3️⃣ دریافت دسته‌بندی‌ها
        const categories = await getCategories(authResult.accessToken);
        StoreService.SetData("categories", categories);
      }

      // 4️⃣ دریافت پروفایل اختصاصی کاربر
      const userProfile = await businessService.getUserProfile();
      console.log("User Profile:", userProfile);

      if (userProfile) {
        StoreService.SetData("user-profile", userProfile); // ✅ ذخیره پروفایل اختصاصی در StoreService
      }

      // 5️⃣ انتقال به صفحه اصلی
      navigate("/main");
    } catch (error) {
      console.error("Error in authentication:", error);
      alert("خطایی در فرآیند احراز هویت رخ داد.");
    } finally {
      setLoading(false);
    }
  };

  // بازگشت به صفحه قبل
  const handleGoBack = () => {
    navigate("/mobile-verify");
  };

  return (
    <Layout>
      <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        {/* بخش بالایی */}
        <div style={{ flex: "0 0 45%", position: "relative", zIndex: 1 }}>
          <SecondPageTopSection
            backgroundImage={BgImage}
            logoImage={logo}
            title={title}
            description={description}
            customStyles={{
              container: { height: "100%" },
              title: { color: "white" },
              description: { color: "white" },
            }}
          />
        </div>

        {/* بخش پایینی */}
        <div
          style={{
            flex: 1,
            backgroundColor: "#FFFFFF",
            borderRadius: "50px 50px 0 0",
            marginTop: "-50px",
            padding: "20px",
            overflow: "hidden",
            zIndex: 10,
          }}
        >
          <div className="text-center mb-4">
            <i className="bi bi-chat-dots-fill" style={{ fontSize: "3rem", color: "#6200EA" }}></i>
          </div>
          <h6 className="fw-bold text-center mb-3">لطفاً کد ارسال شده را وارد کنید</h6>
          <p className="text-center text-secondary mb-4">
            کد به شماره <strong>{mobile || "09123456789"}</strong> ارسال شده است.
          </p>
          <input
            type="tel"
            inputMode="numeric"
            maxLength={4}
            className="form-control text-center fs-4 mb-4"
            placeholder="----"
            style={{
              letterSpacing: "10px",
              padding: "10px",
              backgroundColor: "#F5F5F5",
              borderRadius: "8px",
            }}
            value={code}
            onChange={handleCodeChange}
          />
          <p className="text-center text-secondary mb-3">
            در انتظار دریافت کد{" "}
            <button
              className="btn btn-link text-primary p-0"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleResendCode}
              disabled={loading}
            >
              ارسال مجدد
            </button>{" "}
            ({Math.floor(ttl / 60)}:{ttl % 60})
          </p>
          <button
            className="btn btn-primary w-100 mb-3"
            style={{
              height: "50px",
              borderRadius: "24px",
              fontWeight: "bold",
              fontSize: "16px",
              backgroundColor: "#695CD5",
            }}
            onClick={handleContinue}
            disabled={loading}
          >
            {loading ? "در حال پردازش..." : "ادامه"}
          </button>
          <p className="text-center text-secondary">
            <button
              className="btn btn-link text-primary p-0"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleGoBack}
            >
              بازگشت به مرحله قبل
            </button>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default MobileOtpPage;
