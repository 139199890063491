import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import TopSection from "../components/TopSection/FirstPageTopSection";
import InfoBox from "../components/InfoBox/FirstPageInfoBox";
import { StoreService } from "../services/store-service"; // ایمپورت سرویس مدیریت ذخیره‌سازی
import DefaultLogo from "../assets/images/logo.png";

const HelpPage: React.FC = () => {
  const navigate = useNavigate();

  // دریافت داده از لوکال استورج
  const businessProfile = StoreService.GetData("business-profile");

  // تنظیم مقدار پیش‌فرض در صورت نبود داده
  const logoSrc = businessProfile?.logo || DefaultLogo;
  const title = businessProfile?.name || "سالن زیبایی مینا راد";
  const subtitle = businessProfile?.slogan || "لاین زیبایی";

  const steps = [
    "1. در نوار پایین دکمه Share را انتخاب کنید.",
    "2. در منوی باز شده، گزینه Add to home screen را انتخاب کنید.",
    "3. در مرحله بعد، گزینه Add را انتخاب کنید.",
  ];

  return (
    <Layout>
      <TopSection logoSrc={logoSrc} title={title} subtitle={subtitle} />

      {/* توضیحات */}
      <div
        className="container text-center my-3 py-2"
        style={{
          maxWidth: "300px", // حداکثر عرض
          color: "#332f5c", // رنگ متن
        }}
      >
        <p
          className="fw-semibold"
          style={{
            fontSize: "16px", // اندازه متن
            lineHeight: "190%", // فاصله خطوط
          }}
        >
          وب اپلیکیشن {title} را به صفحه اصلی گوشی‌تان اضافه کنید
        </p>
      </div>

      {/* جعبه مراحل */}
      <InfoBox
        steps={steps}
        buttonText="متوجه شدم"
        onButtonClick={() => navigate("/mobile-verify")}
      />
    </Layout>
  );
};

export default HelpPage;
