"use server";

import { BusinessServiceProxy , MobileCategoryDto  , MobilePartnersDto , CustomerServiceProxy, MobileServiceProxy, MobileCustomerProfileDto , MobileTimesheetDto  ,MobileCustomerProfileTimeDto ,MobileCustomerProfileTransactionDto ,  SearchServiceProxy , TimesServiceProxy  } from "./shared/service-proxies/service-proxies";
import { AppConsts } from "../core/AppConsts";
import { AxiosConfigure } from "../core/AxiosConfigure";
import { DateTime } from "ts-luxon";
import { cache } from "react";


// ایجاد نمونه سرویس مشتری
const customerService = new CustomerServiceProxy(AppConsts.appBaseUrl, AxiosConfigure.getInstance());

// ایجاد نمونه سرویس موبایل
const mobileService = new MobileServiceProxy(AppConsts.appBaseUrl, AxiosConfigure.getInstance());

// نمونه‌ای از سرویس جستجو
const searchService = new SearchServiceProxy(AppConsts.appBaseUrl, AxiosConfigure.getInstance());


// ایجاد نمونه سرویس تایم‌شیت
const timesService = new TimesServiceProxy(AppConsts.appBaseUrl, AxiosConfigure.getInstance());

const businessServiceProxy = new BusinessServiceProxy( AppConsts.appBaseUrl, AxiosConfigure.getInstance());
 
 


/**
 * ✅ دریافت لیست تراکنش‌های کاربر به عنوان یک Server Action
 * 📦 با استفاده از کش برای بهینه‌سازی درخواست‌ها
 */
export const getTransactions = cache(async (): Promise<MobileCustomerProfileTransactionDto[]> => {
  try {
    return await customerService.transactions(); // `fromJS` قبلاً در `processTransactions` اجرا شده است
  } catch (error) {
    console.error("❌ خطا در دریافت تراکنش‌های کاربر:", error);
    if (error instanceof Error) {
      throw new Error(`خطایی در دریافت تراکنش‌های کاربر رخ داده است: ${error.message}`);
    }
    throw new Error("خطایی ناشناخته در دریافت تراکنش‌های کاربر رخ داده است.");
  }
});


/**
 * ✅ دریافت پروفایل مشتری به عنوان یک Server Action
 * @returns اطلاعات پروفایل مشتری
 */
// ✅ کش کردن نتیجه درخواست
export const getCustomerProfile = cache(async (): Promise<MobileCustomerProfileDto> => {
  try {
    return await mobileService.customerGet();
  } catch (error) {
    console.error("❌ خطا در دریافت پروفایل مشتری:", error);
    throw new Error("خطایی در دریافت اطلاعات مشتری رخ داده است. لطفاً دوباره تلاش کنید.");
  }
});

/**
 * ✅ بروزرسانی پروفایل کاربر به عنوان یک Server Action
 * @param updatedProfile اطلاعات جدید پروفایل
 * @returns پروفایل بروزرسانی شده
 */
export async function updateUserProfile(updatedProfile: MobileCustomerProfileDto): Promise<MobileCustomerProfileDto> {
  try {
    return await mobileService.customerPost(updatedProfile);
  } catch (error) {
    console.error("❌ خطا در بروزرسانی پروفایل کاربر:", error);
    throw new Error("بروزرسانی پروفایل انجام نشد. لطفاً دوباره تلاش کنید.");
  }
}



/**
 * ✅ دریافت نتایج جستجو به عنوان یک Server Action
 * @param query متن جستجو
 * @returns داده‌های پردازش شده از API
 */
export async function searchQuery(query: string) {
  try {
    const results = await searchService.query(query);

    // 🔥 پردازش داده‌های API قبل از ارسال به UI
    return {
      categories: results.categories ?? [],
      partners: results.partners ?? [],
      popular: results.popular ?? [],
    };
  } catch (error) {
    console.error("❌ خطا در دریافت نتایج جستجو:", error);
    throw error;
  }
}


/**
 * ✅ دریافت لیست تایم‌های کاربر به عنوان یک Server Action
 * MobileCustomerProfileTimeDto وضیفش اینجا برای گرفتن دیتاهست
 * ولی توی کامپوننت ReservedTimes   برای مشخص کردن type هست بجای interface
 */

export async function getCustomerTimes(): Promise<MobileCustomerProfileTimeDto[]> {
  try {
    return await customerService.times();
  } catch (error) {
    console.error("❌ خطا در دریافت تایم‌های کاربر:", error);
    throw new Error("خطایی در دریافت تایم‌های کاربر رخ داده است. لطفاً دوباره تلاش کنید.");
  }
}


/**
 * ✅ دریافت لیست تایم‌های کاربر به عنوان یک Server Action
 */
export async function getTimes(): Promise<MobileCustomerProfileTimeDto[]> {
  try {
    const times = await customerService.times();
    return times;
  } catch (error) {
    console.error("❌ خطا در دریافت تایم‌های کاربر:", error);
    throw new Error("خطایی در دریافت تایم‌های کاربر رخ داده است. لطفاً دوباره تلاش کنید.");
  }
}




export async function getTimesheet(
  onDate: string,
  categoryId?: number,
  partnerId?: number
): Promise<MobileTimesheetDto[]> {
  try {
    // ✅ تبدیل `onDate` به `DateTime`
    const dateTime = DateTime.fromISO(onDate);
    if (!dateTime.isValid) {
      throw new Error("فرمت تاریخ نامعتبر است.");
    }

    // ✅ ارسال درخواست به API و دریافت داده‌های پردازش‌شده
    const result = await timesService.timesheet(dateTime, categoryId, partnerId);

    // 🔥 بررسی مقدار خروجی
    if (!result || result.length === 0) {
      console.warn("⏳ تایم‌شیتی یافت نشد.");
      return [];
    }

    return result;
  } catch (error) {
    console.error("❌ خطا در دریافت تایم‌شیت:", error);
    throw new Error("خطا در دریافت تایم‌شیت. لطفاً دوباره تلاش کنید.");
  }
}



/**
 * دریافت لیست شرکا از API
 * @returns لیست شرکا به فرمت `MobilePartnersDto[]`
 */
export async function getPartners(): Promise<MobilePartnersDto[]> {
  try {
    const partners = await businessServiceProxy.partners();
    
    if (!Array.isArray(partners)) {
      throw new Error("Invalid data received for partners.");
    }

    // تبدیل داده‌ها به شیء‌های MobilePartnersDto
    const partnersData = partners.map(MobilePartnersDto.fromJS);

    // بررسی و ذخیره‌سازی داده‌ها در localStorage تنها در صورتی که تغییر کرده باشند
    const existingData = localStorage.getItem("partners");
    if (existingData) {
      const parsedData = JSON.parse(existingData);
      if (JSON.stringify(parsedData) !== JSON.stringify(partnersData)) {
        localStorage.setItem("partners", JSON.stringify(partnersData));
      }
    } else {
      localStorage.setItem("partners", JSON.stringify(partnersData));
    }

    return partnersData;
  } catch (error) {
    console.error("❌ خطا در دریافت لیست شرکا:", error);
    throw error;
  }
}



/**
 * ✅ دریافت لیست دسته‌بندی‌ها به عنوان یک Server Action
 * @param accessToken توکن دسترسی
 * @returns لیست دسته‌بندی‌ها
 */
export const getCategories = cache(async (accessToken: string): Promise<MobileCategoryDto[]> => {
  try {
    const categories = await businessServiceProxy.categories(accessToken); // داده‌ها از قبل توسط processCategories تبدیل شده‌اند
    if (!Array.isArray(categories)) {
      throw new Error("داده‌های دریافت‌شده برای دسته‌بندی‌ها نامعتبر است.");
    }
    return categories; // داده‌ها به صورت آماده بازگردانده می‌شوند
  } catch (error) {
    console.error("❌ خطا در دریافت دسته‌بندی‌ها:", error);
    if (error instanceof Error) {
      throw new Error(`خطا در دریافت دسته‌بندی‌ها: ${error.message}`);
    }
    throw new Error("خطای ناشناخته در دریافت دسته‌بندی‌ها.");
  }
});
