import React, { useState, useEffect, useRef, useTransition, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AvilableServices.module.css";
import defaultImage from "../../assets/images/freeface.jpg";
import clockIcon from "../../assets/icons/ClockIcon.svg";
import { ReactComponent as NotFoundTimesIcon } from "../../assets/icons/NotFoundTimes.svg";
import { StoreService } from "../../services/store-service";
import { MobileTimesheetDto } from "../../services/shared/service-proxies/service-proxies";
import { DateTime } from "ts-luxon";

interface AvilableServicesProps {
  reservations: MobileTimesheetDto[];
  selectedCategoryId: number | null;
  selectedPartnerId: number | null;
  layout?: "vertical" | "horizontal";
}

const ReservationItem: React.FC<{ reservation: MobileTimesheetDto }> = React.memo(({ reservation }) => {
  const navigate = useNavigate();

  const categories = StoreService.GetData("categories") || [];
  const partners = StoreService.GetData("partners") || [];

  const serviceName = categories.find((c: any) => c.id === reservation.categoryId)?.name || "نامشخص";
  const stylist = partners.find((p: any) => p.id === reservation.partnerId);
  const stylistName = stylist ? `${stylist.firstname} ${stylist.lastname}` : "نامشخص";

  const formattedFromTime = reservation.fromTime
    ? DateTime.fromISO(reservation.fromTime.toString()).toFormat("HH:mm")
    : "نامشخص";
  const formattedToTime = reservation.toTime
    ? DateTime.fromISO(reservation.toTime.toString()).toFormat("HH:mm")
    : "نامشخص";

  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <img src={defaultImage} alt={stylistName} className={styles.stylistImage} />
        <div className={styles.textContainer}>
          <h6 className={styles.serviceTitle}>{serviceName}</h6>
          <p className={styles.stylistName}>{stylistName}</p>
        </div>
      </div>
      <div className={styles.cardFooter}>
        <div
          className={styles.reserveButton}
          onClick={() =>
            navigate("/service-details", {
              state: { service: serviceName, stylist: stylistName },
            })
          }
        >
          <span className={styles.reserveArrow}>&lt;</span>
          رزرو وقت
        </div>
        <div className={styles.timeContainer}>
          <span className={styles.timeText}>
            {formattedFromTime} - {formattedToTime}
          </span>
          <img src={clockIcon} alt="Clock" className={styles.clockIcon} />
        </div>
      </div>
    </div>
  );
});

const AvilableServices: React.FC<AvilableServicesProps> = ({
  reservations,
  selectedCategoryId,
  selectedPartnerId,
  layout = "vertical",
}) => {
  const [visibleReservations, setVisibleReservations] = useState<MobileTimesheetDto[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPending, startTransition] = useTransition();
  const observerRef = useRef<HTMLDivElement>(null);

  const itemsPerPage = 10;

  const partners = useMemo(() => {
    return StoreService.GetData("partners") || [];
  }, []);

  const filteredReservations = useMemo(() => {
    return reservations.filter((reservation) => {
      const matchesCategory =
        selectedCategoryId === null || reservation.categoryId === selectedCategoryId;
      const matchesPartner =
        selectedPartnerId === null || reservation.partnerId === selectedPartnerId;
      return matchesCategory && matchesPartner;
    });
  }, [reservations, selectedCategoryId, selectedPartnerId]);

  useEffect(() => {
    setVisibleReservations(filteredReservations.slice(0, itemsPerPage));
    setCurrentPage(1);
  }, [filteredReservations]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.isIntersecting &&
          !isPending &&
          visibleReservations.length < filteredReservations.length
        ) {
          startTransition(() => {
            const nextPage = currentPage + 1;
            const newItems = filteredReservations.slice(
              currentPage * itemsPerPage,
              nextPage * itemsPerPage
            );

            if (newItems.length > 0) {
              setVisibleReservations((prev) => [...prev, ...newItems]);
              setCurrentPage(nextPage);
            }
          });
        }
      },
      { threshold: 0.8 }
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) observer.observe(currentObserverRef);

    return () => {
      if (currentObserverRef) observer.unobserve(currentObserverRef);
    };
  }, [currentPage, filteredReservations, isPending, visibleReservations.length]);

  const selectedPartnerName = useMemo(() => {
    const partner = partners.find((p: any) => p.id === selectedPartnerId);
    return partner ? `${partner.firstname} ${partner.lastname}` : "نامشخص";
  }, [partners, selectedPartnerId]);

  return (
    <div
      className={layout === "horizontal" ? styles.horizontalScroll : styles.verticalScroll}
    >
      {visibleReservations.length > 0 ? (
        visibleReservations.map((reservation, index) => (
          <ReservationItem key={index} reservation={reservation} />
        ))
      ) : (
        <div className={styles.noReservationsContainer}>
          <NotFoundTimesIcon className={styles.notFoundIcon} />
          <p className={styles.noReservations}>
            {selectedCategoryId !== null && selectedPartnerId === null
              ? "سرویس انتخاب‌شده در این تاریخ وجود ندارد."
              : `سرویس ارائه‌شده توسط ${selectedPartnerName} وجود ندارد.`}
          </p>
        </div>
      )}
      <div ref={observerRef} style={{ height: "1px", width: "100%" }} />
    </div>
  );
};

export default AvilableServices;
