import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceItem from "./ServiceItem";
import styles from "./Services.module.css";
import { StoreService } from "../../services/store-service";

interface ServicesProps {
  searchQuery: string;
}

const Services: React.FC<ServicesProps> = ({ searchQuery }) => {
  const navigate = useNavigate();
  const [services, setServices] = useState<any[]>([]);

  // دریافت داده‌ها از localStorage هنگام بارگذاری کامپوننت
  useEffect(() => {
    const storedCategories = StoreService.GetData("categories");

    if (storedCategories) {
      setServices(storedCategories);
    }
  }, []);

  // فیلتر کردن بر اساس searchQuery
  const filteredServices = services.filter((service) =>
    service.name.includes(searchQuery)
  );

  const handleServiceClick = (serviceId: number) => {
    navigate(`/times-page?service=${serviceId}`); // هدایت به صفحه TimesPage با serviceId
  };

  return (
    <section className={styles.servicesSection}>
      <div className={styles.servicesHeader}>
        <button
          onClick={() => navigate("/ServiceList")}
          className={styles.viewAllButton}
        >
          مشاهده همه
        </button>
        <h6 className="text-dark fw-bold">سرویس‌ها</h6>
      </div>

      <div className={`${styles.customScrollbar}`}>
        <div className={styles.column}>
          <div className={styles.row}>
            {filteredServices
              .slice(0, Math.ceil(filteredServices.length / 2))
              .map((service) => (
                <ServiceItem
                  key={service.id}
                  id={service.id} // اضافه کردن id به ServiceItem
                  name={service.name}
                  icon={service.logo}
                  onClick={() => handleServiceClick(service.id)} // ارسال id به تابع کلیک
                />
              ))}
          </div>

          <div className={styles.row}>
            {filteredServices
              .slice(Math.ceil(filteredServices.length / 2))
              .map((service) => (
                <ServiceItem
                  key={service.id}
                  id={service.id} // اضافه کردن id به ServiceItem
                  name={service.name}
                  icon={service.logo}
                  onClick={() => handleServiceClick(service.id)} // ارسال id به تابع کلیک
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
