import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./SelectedServiceSection.module.css";

const SelectedServiceSection: React.FC = () => {
  const location = useLocation();

  // دریافت اطلاعات سرویس و آیکون
  const service = location.state?.service || "سرویسی انتخاب نشده است"; // مقدار پیش‌فرض در صورت نبود اطلاعات
  const icon = location.state?.icon || "/icons/default-icon.svg"; // مقدار پیش‌فرض برای آیکون

  return (
    <div className={styles.serviceContainer}>
      <h1 className={styles.title}>سرویس </h1>
      <div className={styles.serviceItem}>
        <img
          src={icon}
          alt="Service Icon"
          className={styles.serviceIcon} // نمایش آیکون سرویس
        />
        <p className={styles.serviceName}>{service}</p> {/* نمایش نام سرویس */}
        
      </div>
      <div className={styles.divider}></div>
    </div>
  );
};

export default SelectedServiceSection;
