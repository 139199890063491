import React, { useState } from "react";
import styles from "./FilterSection.module.css";
import { ReactComponent as NotFoundTimesIcon } from "../../assets/icons/NotFoundTimes.svg";

interface FilterSectionProps {
  selectedService: string | null; // سرویس انتخاب‌شده
  selectedPartner: string | null; // پارتنر انتخاب‌شده
  selectedDateISO: string; // تاریخ انتخاب‌شده به فرمت ISO
  onFilterChange: (categoryId: number | null, partnerId: number | null) => void; // هندل تغییرات فیلتر
  services: { id: number; name: string }[]; // لیست سرویس‌ها
  partners: { id: number; name: string; services: number[] }[]; // لیست پارتنرها (شامل سرویس‌های پشتیبانی‌شده)
  hasReservations: boolean; // آیا داده‌ای برای نمایش وجود دارد
}

interface DropdownProps {
  title: string;
  items: { id: number; name: string }[];
  selectedItem: number | null;
  onItemSelect: (item: number | null) => void;
  isOpen: boolean;
  toggleDropdown: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  items,
  selectedItem,
  onItemSelect,
  isOpen,
  toggleDropdown,
}) => {
  return (
    <div className={styles.dropdown}>
      <button className={styles.button} onClick={toggleDropdown}>
        <span className={`${styles.icon} ${isOpen ? styles.iconOpen : ""}`}>&lt;</span>
        <span>{selectedItem !== null ? items.find((i) => i.id === selectedItem)?.name : title}</span>
      </button>
      {isOpen && (
        <ul className={styles.list}>
          <li className={styles.listItem} onClick={() => onItemSelect(null)}>
            <span>همه</span>
          </li>
          {items.map((item) => (
            <li
              key={item.id}
              className={`${styles.listItem} ${selectedItem === item.id ? styles.listItemActive : ""}`}
              onClick={() => onItemSelect(item.id)}
            >
              <span>{item.name}</span>
              {selectedItem === item.id && <span className={styles.selectedIcon}>✔</span>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const FilterSection: React.FC<FilterSectionProps> = ({
  selectedService,
  selectedPartner,
  onFilterChange,
  services,
  partners,
  hasReservations, // بررسی وضعیت رزرو‌ها
}) => {
  const [openDropdown, setOpenDropdown] = useState<"services" | "partners" | null>(null);
  const [localService, setLocalService] = useState<number | null>(
    selectedService ? parseInt(selectedService) : null
  );
  const [localPartner, setLocalPartner] = useState<number | null>(
    selectedPartner ? parseInt(selectedPartner) : null
  );

  const updateFilter = (categoryId: number | null, partnerId: number | null) => {
    setLocalService(categoryId);
    setLocalPartner(partnerId);
    onFilterChange(categoryId, partnerId);
  };

  const toggleDropdown = (dropdown: "services" | "partners" | null) => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  return (
    <div className={styles.container}>
      <Dropdown
        title="سرویس‌ها"
        items={services}
        selectedItem={localService}
        onItemSelect={(service) => {
          updateFilter(service, localPartner);
          toggleDropdown(null);
        }}
        isOpen={openDropdown === "services"}
        toggleDropdown={() => toggleDropdown("services")}
      />
      <Dropdown
        title="آرایشگر"
        items={partners}
        selectedItem={localPartner}
        onItemSelect={(partner) => {
          updateFilter(localService, partner);
          toggleDropdown(null);
        }}
        isOpen={openDropdown === "partners"}
        toggleDropdown={() => toggleDropdown("partners")}
      />
      {!hasReservations && (
        <div className={styles.notFoundContainer}>
          <NotFoundTimesIcon className={styles.notFoundIcon} />
          <p>
            سرویس انتخاب‌شده <strong>{services.find((s) => s.id === localService)?.name}</strong> توسط
            آرایشگر <strong>{partners.find((p) => p.id === localPartner)?.name}</strong> در دسترس نیست.
          </p>
        </div>
      )}
    </div>
  );
};

export default FilterSection;
