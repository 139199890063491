import React, { useEffect, useState } from "react";
import { StoreService } from "../services/store-service";
import Layout from "../components/Layout/Layout";
import styles from "./ServiceList.module.css";
import ArrowLeftIcon from "../assets/icons/arrowLeft.svg";
import ServiceCard from "./ServiceCard";

interface Service {
  id: number;
  name: string;
  logo: string;
  description: string;
}

const ServiceList: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    const storedServices = StoreService.GetData("categories");
    if (storedServices) {
      setServices(storedServices);
    }
  }, []);

  return (
    <Layout>
      <div className={`${styles.serviceListPage} container px-3`}>
        <div className={styles.serviceListHeader}>
          <h6 className="m-0 fw-bold">سرویس‌ها</h6>
          <button className="btn p-0" onClick={() => window.history.back()}>
            <img
              src={ArrowLeftIcon}
              alt="Back Arrow"
              width="24"
              height="24"
              className="arrow-right"
            />
          </button>
        </div>

        <div className={styles.serviceListContent}>
          {services.map((service: Service) => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ServiceList;
