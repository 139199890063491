import React from "react";
import SearchBar from "./SearchBar";
import { StoreService } from "../../services/store-service";
import DefaultLogo from "../../assets/images/logo.png";
import styles from "./Header.module.css";

interface HeaderProps {
  onSearch?: (query: string) => void; // ✅ `onSearch` را اختیاری کردیم
}

const Header: React.FC<HeaderProps> = ({ onSearch }) => {
  // دریافت اطلاعات سالن از `localStorage`
  const businessProfile = StoreService.GetData("business-profile");

  // مقداردهی بر اساس اطلاعات `localStorage` یا مقدار پیش‌فرض
  const logo = businessProfile?.logo || DefaultLogo;
  const title = businessProfile?.name || "سالن زیبایی مینا راد";

  return (
    <div className={`shadow-sm py-4 px-4 ${styles.headerContainer}`}>
      {/* لوگو و متن خوش‌آمدگویی */}
      <div className="d-flex align-items-center justify-content-end">
        <div className={`me-2 text-end ${styles.welcomeText}`}>
          <h5 className="fw-bold text-dark mb-1">{title}</h5>
          <p className="text-secondary mb-0">خوش آمدید</p>
        </div>
        <div className={styles.logoWrapper}>
          <img src={logo} alt="لوگو" className={styles.logo} />
        </div>
      </div>

      {/* نوار جستجو */}
      <div className={`mt-3 mx-auto ${styles.searchContainer}`}>
        <SearchBar onSearch={onSearch ?? (() => {})} /> {/* ✅ مقدار پیش‌فرض دادیم */}
      </div>
    </div>
  );
};

export default Header;


