import { ReportHandler } from 'web-vitals';

const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    try {
      // بارگیری متریک‌ها به صورت lazy loading
      const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals');

      // ارسال هر یک از متریک‌ها
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    } catch (error) {
      // مدیریت خطا در هنگام وارد کردن یا فراخوانی
      console.error('Error loading web vitals:', error);
    }
  }
};

export default reportWebVitals;
