"use client";

import React from "react";
import styles from "./CardItem.module.css";
import defaultImage from "../../assets/images/freeface.jpg"; // تصویر پیش‌فرض
import { MobileCustomerProfileTimeDto } from "../../services/shared/service-proxies/service-proxies";

const CardItem: React.FC<{ data: MobileCustomerProfileTimeDto }> = ({ data }) => {
  // بررسی تصویر معتبر و تنظیم پیش‌فرض
  const validImage = data.partnerId ? `/images/partner/${data.partnerId}.jpg` : defaultImage;

  return (
    <div className={styles.card}>
      {/* هدر کارت */}
      <div className={styles.cardHeader}>
        <div className={styles.statusSection}>
          <span className={styles.statusText}>رزرو شده</span>
          <span className={styles.statusIcon}>✔</span>
        </div>
      </div>

      {/* بدنه کارت */}
      <div className={styles.cardBody}>
        <img
          src={validImage}
          alt={`آرایشگر شماره ${data.partnerId}`}
          className={styles.cardImage}
          onError={(e) => {
            (e.target as HTMLImageElement).src = defaultImage; // جایگزینی تصویر پیش‌فرض در صورت خطا
          }}
        />
        <div className={styles.textContainer}>
          <h6 className={styles.title}>{`خدمت شماره ${data.categoryId}`}</h6>
          <p className={styles.subtitle}>{`آرایشگر شماره ${data.partnerId}`}</p>
        </div>
      </div>

      {/* فوتر کارت */}
      <div className={styles.cardFooter}>
        <div className={styles.timeContainer}>
          <span>{`${data.fromTime} تا ${data.toTime}`}</span>
          <span className={styles.clockImage}>⏰</span>
        </div>
        <div className={styles.dateContainer}>
          <span>{data.onDate?.toFormat("yyyy/MM/dd") ?? "نامشخص"}</span>
          <span className={styles.dateIcon}>📅</span>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
