import React, { useEffect, useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import Layout from "../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { BusinessService } from "../services/business-service";
import { StoreService } from "../services/store-service";
import { AuthService } from "../services/auth-service";
import { getCategories } from "../services/server-actions"; // ✅ وارد کردن getCategories
import Clarity from "@microsoft/clarity";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SplashScreen: React.FC = () => {
  const navigate = useNavigate();

  const businessService = useMemo(() => new BusinessService(), []); 

  const fetchProfile = useCallback(async () => {
    try {
      const profile = await businessService.getProfile("Default");

      profile.logo =
        "data:image/jpeg;base64," +
        (await businessService.getFile(profile.logo, profile.accessKey));
      profile.background =
        "data:image/jpeg;base64," +
        (await businessService.getFile(profile.background, profile.accessKey));
      profile.posterImage =
        "data:image/jpeg;base64," +
        (await businessService.getFile(profile.posterImage, profile.accessKey));

      StoreService.SetData("business-profile", profile);

      const user = StoreService.GetData("user");
      Clarity.setTag("user", user.userId);

      if (!user) {
        navigate("/mobile-verify", { state: { profile } });
        return;
      }

      const authService = new AuthService(user);
      const session = await authService.getCurrrentSession();

      if (!session || !session.user) {
        navigate("/mobile-verify", { state: { profile } });
        return;
      }

      StoreService.SetData("user-session", session);

      const accessToken = StoreService.GetData("accessKey");
      if (accessToken) {
        const categories = StoreService.GetData("categories");
        if (!categories) {
          const fetchedCategories = await getCategories(accessToken);
          StoreService.SetData("categories", fetchedCategories);
        }
      }
      

      navigate("/main");
    } catch (error) {
      console.error("Error fetching profile or additional data:", error);
      navigate("/mobile-verify");
    }
  }, [businessService, navigate]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          backgroundColor: "#695CD5",
          color: "white",
          fontSize: "2rem",
          borderRadius: 0,
          overflow: "hidden",
          boxSizing: "border-box",
          padding: { xs: "10px", sm: "0px" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "1.2rem", sm: "clamp(1.5rem, 5vw, 2rem)" },
            animation: `${fadeIn} 1.5s ease-in-out`,
            textAlign: "center",
          }}
        >
          Welcome to My App
        </Typography>
      </Box>
    </Layout>
  );
};

export default SplashScreen;
