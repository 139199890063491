export class AppConsts {
    static appBaseUrl: string = 'https://api.stage.biztime.ir';
    static projectId: string = 'pv8ur0d2yj';

    // شما می‌توانید متغیرهای دیگری مانند این نیز اضافه کنید:
    static environment: string = 'development';  // یا 'production'
    static apiVersion: string = 'v1';

    // مثال از یک متد برای دسترسی به آدرس کامل API با نسخه مشخص:
    static getApiUrl(endpoint: string): string {
        return `${this.appBaseUrl}/${this.apiVersion}/${endpoint}`;
    }
}
