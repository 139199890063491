import React, { Suspense } from "react";
import ProfileMenuList from "../components/Profile/ProfileMenuList";
import SupportSection from "../components/Profile/SupportSection";
import ContactSection from "../components/ContactSection/ContactSection";
import NavigationMenu from "../components/NavigationMenu/NavigationMenu";
import Layout from "../components/Layout/Layout";
import styles from "./Profile.module.css"; // ✅ مطمئن شوید که استایل ایمپورت شده است

// ✅ `ProfileHeader` را به‌صورت Lazy Load لود می‌کنیم
const ProfileHeader = React.lazy(() => import("../components/Profile/ProfileHeader"));

const Profile: React.FC = () => {
  return (
    <Layout>
      <div className={styles.pageContainer}> {/* ✅ استفاده از styles.pageContainer به جای container */}
        {/* ✅ `Suspense` با `spinner` در مرکز صفحه */}
        <Suspense
          fallback={
            <div className={styles.spinnerContainer}> {/* ✅ استفاده از styles.spinnerContainer */}
              <div className={styles.spinner}></div> {/* ✅ نمایش لودر وسط صفحه */}
            </div>
          }
        >
          <ProfileHeader />
        </Suspense>

        <ProfileMenuList />
        <SupportSection profile={undefined} />
        <ContactSection />
        <NavigationMenu activeTab="profile" />
      </div>
    </Layout>
  );
};

export default Profile;
