import React, { useState, useEffect } from "react";
import defaultProfileImage from "../../assets/images/freeface.jpg";
import styles from "./ProfileImage.module.css";
import { StoreService } from "../../services/store-service"; // برای دریافت تصویر از localStorage

interface ProfileImageProps {
  image?: string;
  altText?: string;
  size?: number;
  partnerId?: number;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  image,
  altText = "Profile Image",
  size = 120,
  partnerId,
}) => {
  const [profileImage, setProfileImage] = useState<string>(
    image && typeof image === 'string' && image.trim() ? image : defaultProfileImage
  );

  useEffect(() => {
    if (!image && partnerId !== undefined) {
      const storedPartners = StoreService.GetData("partners") || [];
      const foundPartner = storedPartners.find((partner: any) => partner.id === partnerId);
      
      if (foundPartner?.profile) {
        // اگر profile یک شناسه است، باید به URL تبدیل شود
        const imageUrl = foundPartner.profile.startsWith("http") 
          ? foundPartner.profile // اگر URL معتبر است
          : `https://your-cdn-url.com/${foundPartner.profile}`; // تبدیل به URL کامل در صورت نیاز

        setProfileImage(imageUrl);
      } else {
        setProfileImage(defaultProfileImage);
      }
    }
  }, [image, partnerId]);

  const handleImageError = () => {
    setProfileImage(defaultProfileImage);
  };

  return (
    <div
      className={styles.container}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <img
        src={profileImage}
        alt={altText}
        className={styles.image}
        style={{
          width: `${size - 10}px`,
          height: `${size - 10}px`,
        }}
        onError={handleImageError}
      />
    </div>
  );
};

export default ProfileImage;
