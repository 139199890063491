"use client"; // 🚀 مشخص کردن این کامپوننت به عنوان Client Component

import React, { useEffect, useState } from "react";
import { getTransactions } from "../../../services/server-actions";
import { ReactComponent as ArrowUp } from "../../../assets/icons/RedUpArrow.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/GreendownArrow.svg";
import { ReactComponent as NotFoundIcon } from "../../../assets/icons/NotFound.svg";
import Layout from "../../Layout/Layout";
import HeaderTitle from "../Header/HeaderTitle";
import styles from "./Transactions.module.css";
import { MobileCustomerProfileTransactionDto } from "../../../services/shared/service-proxies/service-proxies";

const TransactionsList: React.FC<{
  transactions: MobileCustomerProfileTransactionDto[];
}> = ({ transactions }) => (
  <div className={styles.transactionsList}>
    {transactions.map((transaction) => (
      <div
        key={
          String(transaction.partnerId) + (transaction.onDate?.toString() || "")
        }
        className={styles.transactionItem}
      >
        <div className={styles.transactionInfo}>
          امروز
          <span className={styles.date}>
            {transaction.onDate
              ? transaction.onDate.toFormat("HH:mm")
              : "نامشخص"}
          </span>
        </div>
        <div className={styles.transactionAmount}>
          <div className={styles.amountDescriptionContainer}>
            <span
              className={
                transaction.type === 1 ? styles.increase : styles.decrease
              }
            >
              {transaction.amount !== 0
                ? `${transaction.amount.toLocaleString()} تومان`
                : "بدون تغییر"}
            </span>
            <p className={styles.description}>
              {transaction.description ?? "بدون توضیح"}
            </p>
            <p className={styles.partnerId}>
              {transaction.partnerId !== 0
                ? `کد شریک: ${transaction.partnerId}`
                : "بدون شریک"}
            </p>
          </div>
          <div className={styles.icon}>
            {transaction.type === 1 ? <ArrowDown /> : <ArrowUp />}
          </div>
        </div>
      </div>
    ))}
  </div>
);

const Transactions: React.FC = () => {
  const [transactions, setTransactions] = useState<
    MobileCustomerProfileTransactionDto[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      setError(null);
      try {
        // بررسی کش محلی (localStorage)
        const cachedTransactions = localStorage.getItem("transactions");
        if (cachedTransactions) {
          setTransactions(JSON.parse(cachedTransactions));
          return; // اگر کش موجود بود، درخواست دیگری ارسال نمی‌شود.
        }

        const data = await getTransactions();
        setTransactions(data);

        // ذخیره در کش محلی
        localStorage.setItem("transactions", JSON.stringify(data));
      } catch (error) {
        console.error("❌ خطا در دریافت تراکنش‌ها:", error);
        setError(
          "مشکلی در دریافت اطلاعات رخ داده است. لطفاً دوباره تلاش کنید."
        );
      }
    };

    fetchTransactions();
  }, []);

  return (
    <Layout>
      <HeaderTitle title="تاریخچه تراکنش‌ها" onBackNavigateTo="/profile" />
      <div className={styles.transactionsContainer}>
        {error && <p className={styles.error}>{error}</p>}
        {transactions.length > 0 ? (
          <TransactionsList transactions={transactions} />
        ) : (
          <div className={styles.noDataMessage}>
            <NotFoundIcon className={styles.notFoundIcon} />
            <p>تراکنشی برای شما ثبت نشده است</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Transactions;
