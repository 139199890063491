import React from "react";
import "./SecondPageTopSection.css";

interface SecondPageTopSectionProps {
  backgroundImage?: string; // تصویر پس‌زمینه
  logoImage?: string; // تصویر لوگو
  title?: string; // عنوان
  description?: string; // توضیحات
  customStyles?: {
    container?: React.CSSProperties;
    title?: React.CSSProperties;
    description?: React.CSSProperties;
    avatar?: React.CSSProperties;
    content?: {
      bottom?: number;
      right?: number;
    };
  };
}

const SecondPageTopSection: React.FC<SecondPageTopSectionProps> = ({
  backgroundImage = "/default-background.jpg", // مقدار پیش‌فرض برای تصویر پس‌زمینه
  logoImage = "/default-logo.png", // مقدار پیش‌فرض برای لوگو
  title = "Default Title", // مقدار پیش‌فرض برای عنوان
  description = "Default Description", // مقدار پیش‌فرض برای توضیحات
  customStyles = {}, // استایل‌های سفارشی
}) => {
  return (
    <div
      className="second-page-top-section d-flex flex-column"
      style={{
        backgroundImage: `url(${backgroundImage})`, // استفاده از تصویر پس‌زمینه
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...customStyles.container,
      }}
    >
      {/* لایه نیمه‌شفاف تاریک */}
      <div className="overlay"></div>

      {/* محتوای بخش */}
      <div
        className="second-page-top-section-content"
        style={customStyles.content}
      >
        {/* لوگو */}
        <div
          className="second-page-top-section-logo"
          style={{
            backgroundImage: `url(${logoImage})`, // استفاده از تصویر لوگو
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            ...customStyles.avatar,
          }}
        ></div>

        {/* عنوان */}
        <h3
          className="second-page-top-section-title"
          style={customStyles.title}
        >
          {title}
        </h3>

        {/* توضیحات */}
        <h6
          className="second-page-top-section-description"
          style={customStyles.description}
        >
          {description}
        </h6>
      </div>
    </div>
  );
};

export default SecondPageTopSection;
