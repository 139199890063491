import React from "react";
import { useNavigate } from "react-router-dom"; // برای هدایت کاربر
import styles from "./DresserList.module.css";
import ArrowLeftIcon from "../assets/icons/arrowLeft.svg";

interface Dresser {
  id: number;
  name: string;
  service: string;
  date: string;
  avatar: string;
  icon?: string;
}

const DresserCard: React.FC<{ dresser: Dresser }> = ({ dresser }) => {
  const navigate = useNavigate();

  const handleReserveClick = () => {
    navigate(`/times?stylist=${dresser.id}`); // هدایت به صفحه TimesPage با id آرایشگر
  };

  return (
    <div className={styles.dresserCard}>
      <div className={styles.cardContent}>
        {/* آیکون سمت چپ */}
        <img src={dresser.icon} alt="Service Icon" className={styles.icon} />

        {/* اطلاعات آرایشگر */}
        <div className={styles.info}>
          <h6 className={styles.name}>{dresser.name || "نامشخص"}</h6>
          <p className={styles.service}>{dresser.service || "بدون سرویس"}</p>
        </div>

        {/* عکس پروفایل سمت راست */}
        <div className={styles.avatarContainer}>
          <img src={dresser.avatar} alt="Avatar" className={styles.avatar} />
        </div>
      </div>

      {/* نوار پایین برای رزرو نوبت و اولین وقت خالی */}
      <div className={styles.footerBar}>
        <span className={styles.date}>
          اولین وقت خالی: {dresser.date || "نامشخص"}
        </span>
        <div
          className={styles.reserveContainer}
          onClick={handleReserveClick} // افزودن کلیک برای رزرو نوبت
        >
          <img src={ArrowLeftIcon} alt="Arrow Left" className={styles.arrowIcon} />
          <span className={styles.reserveText}>رزرو نوبت</span>
        </div>
      </div>
    </div>
  );
};

export default DresserCard;
