"use client";

import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import HeaderTitle from "../Header/HeaderTitle";
import CardItem from "../../Common/CardItem";
import { getTimes } from "../../../services/server-actions";
import { MobileCustomerProfileTimeDto } from "../../../services/shared/service-proxies/service-proxies";
import { ReactComponent as NotFoundIcon } from "../../../assets/icons/NotFound.svg"; // ایمپورت SVG
import styles from "./BookingHistory.module.css";

const BookingHistory: React.FC = () => {
  const [reservations, setReservations] = useState<
    MobileCustomerProfileTimeDto[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReservations = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await getTimes();
        setReservations(data);
      } catch (err) {
        console.error("❌ خطا در دریافت نوبت‌ها:", err);
        setError("مشکلی در دریافت اطلاعات رخ داده است.");
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, []);

  return (
    <Layout>
      <HeaderTitle title="تاریخچه نوبت‌ها" onBackNavigateTo="/profile" />
      <div className={styles.container}>
        {error && <p className={styles.error}>{error}</p>}
        {!loading && !error && reservations.length === 0 && (
          <div className={styles.noDataMessage}>
            <NotFoundIcon className={styles.notFoundIcon} />
            <p>.وقتی برای شما ثبت نشده است</p>
          </div>
        )}

        {/* ✅ ارسال `data` به `CardItem` بدون تبدیل اضافی */}
        {reservations.map((reservation) => (
          <CardItem
            key={`${reservation.partnerId}-${reservation.onDate.toMillis()}`}
            data={reservation}
          />
        ))}
      </div>
    </Layout>
  );
};

export default BookingHistory;
