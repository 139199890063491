import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HairdresserCard.module.css";

interface HairdresserCardProps {
  title: string;
  name: string;
  availability: string | null;
}

const HairdresserCard: React.FC<HairdresserCardProps> = ({ title, name, availability }) => {
  const navigate = useNavigate();

  const handleHairdresserClick = () => {
    // هدایت ساده به صفحه /times بدون پارامتر اضافی
    navigate("/times");
  };

  return (
    <div
      className={`card border-0 text-center rounded-4 ${styles.card}`}
      onClick={handleHairdresserClick}
      style={{ cursor: "pointer" }}
    >
      <p className={`text-muted small mb-1 ${styles.title}`}>{title || "بدون عنوان"}</p>
      <h4 className={`${styles.name} ${styles.truncate}`}>{name || "نامشخص"}</h4>
      <div className={styles.availabilityContainer}>
        <div className={styles.icon}>
          <span>&lt;</span>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.textSmall}>اولین وقت خالی:</p>
          <p className={styles.textLarge}>{availability || "تعیین نشده"}</p>
        </div>
      </div>
    </div>
  );
};

export default HairdresserCard;
