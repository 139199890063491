import axios, { AxiosInstance } from "axios";
import { AppConsts } from "./AppConsts";
import { StoreService } from "../services/store-service";
import { AuthenticateResultModel } from "../services/shared/service-proxies/service-proxies";

// برای مدیریت کنسلی درخواست‌ها
const abortControllers: { [key: string]: AbortController } = {};

export class AxiosConfigure {
  private static axiosHttpConfig: AxiosInstance | null = null;

  static getInstance(): AxiosInstance {
    if (!this.axiosHttpConfig) {
      this.axiosHttpConfig = axios.create({
        baseURL: AppConsts.appBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
      });

      // درخواست‌ها
      this.axiosHttpConfig.interceptors.request.use(
        (config) => {
          // بررسی و اضافه کردن توکن در هدر
          const user = StoreService.GetData("user") as AuthenticateResultModel | null;
          if (user?.accessToken) {
            config.headers["Authorization"] = `Bearer ${user.accessToken}`;
          }

          // ایجاد یک AbortController برای کنسل کردن درخواست در صورت نیاز
          const controller = new AbortController();
          config.signal = controller.signal;
          abortControllers[config.url || 'default'] = controller; // ذخیره کردن controller برای کنسل کردن
          
          return config;
        },
        (error) => Promise.reject(error)
      );

      // پاسخ‌ها
      this.axiosHttpConfig.interceptors.response.use(
        (response) => {
          // استفاده از nullish coalescing
          response.data = response.data?.result ?? response.data;
          return response;
        },
        (error) => {
          // مدیریت خطاهای درخواست
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
          }
          return Promise.reject(error);
        }
      );
    }

    return this.axiosHttpConfig;
  }

  // برای کنسل کردن درخواست‌ها از outside
  static cancelRequest(url: string): void {
    if (abortControllers[url]) {
      abortControllers[url].abort();
      console.log(`Request to ${url} has been canceled.`);
    }
  }
}

export default AxiosConfigure;
