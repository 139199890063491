"use client";

import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import Services from "../components/Services/Services";
import HairdresserList from "../components/HairdresserCard/HairdresserList";
import NavigationMenu from "../components/NavigationMenu/NavigationMenu";
import ReservedTimes from "../components/Reserved/ReservedTimes"; // ✅ دیگر نیازی به ارسال props نیست

const MainPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <Layout>
      <div className="pb-8">
        <Header onSearch={handleSearch} />
      </div>

      {/* بخش رزروهای قبلی */}
      <section className="px-3 px-md-4 pb-1 pb-md-8">
        <ReservedTimes /> {/* ✅ بدون props */}
      </section>

      {/* بخش سرویس‌ها */}
      <section style={{ padding: "0", margin: "0" }}>
        <Services searchQuery={searchQuery} />
      </section>

      {/* بخش آرایشگرها */}
      <section className="pb-2" style={{ padding: "0", margin: "0" }}>
        <HairdresserList searchQuery={searchQuery} />
      </section>

      {/* نویگیشن بار */}
      <NavigationMenu />
    </Layout>
  );
};

export default MainPage;
