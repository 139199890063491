import React, { useState, useTransition, useDeferredValue } from "react";
import { useDebounce } from "use-debounce";
import styles from "./SearchBar.module.css";
import { FaSearch } from "react-icons/fa";
import { searchQuery } from "../../services/server-actions";

interface SearchBarProps {
  placeholder?: string;
  onSearch?: (query: string) => void;
  className?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "نام آرایشگر یا سرویس را جستجو کنید",
  className = "",
  onSearch,
}) => {
  const [query, setQuery] = useState<string>("");
  const deferredQuery = useDeferredValue(query);
  const [debouncedQuery] = useDebounce(deferredQuery, 900); // ✅ افزایش زمان دیباونس برای کاهش درخواست‌ها
  const [results, setResults] = useState<{ categories: any[]; partners: any[]; popular: any[] }>({
    categories: [],
    partners: [],
    popular: [],
  });

  const [isPending, startTransition] = useTransition();

  const handleSearch = (searchText: string) => {
    if (searchText !== query) {
      setQuery(searchText);
      onSearch?.(searchText);
    }
  };
  

  React.useEffect(() => {
    if (!debouncedQuery.trim() || debouncedQuery.length < 3) {
      // ✅ جلوگیری از ارسال درخواست برای ورودی‌های کمتر از ۳ کاراکتر
      setResults({ categories: [], partners: [], popular: [] });
      return;
    }

    startTransition(async () => {
      try {
        const data = await searchQuery(debouncedQuery);
        if (JSON.stringify(data) !== JSON.stringify(results)) {
          setResults(data);
        }
      } catch (error) {
        console.error("❌ خطا در جستجو:", error);
      }
    });
    
  },  [debouncedQuery, startTransition]);

  return (
    <div className={`${styles.searchBar} ${className}`}>
      <span className={styles.icon}>
        <FaSearch />
      </span>
      <input
        type="text"
        className={styles.input}
        placeholder={placeholder}
        value={query}
        onChange={(e) => handleSearch(e.target.value)}
        aria-label="Search"
      />
       {isPending && <div className={styles.loader}>در حال جستجو...</div>}
    </div>
  );
};

export default SearchBar;
