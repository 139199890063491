import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setActiveTab } from "../../store/tabSlice";

import HomeActiveIcon from "../../assets/icons/home-active.svg";
import HomeInactiveIcon from "../../assets/icons/home-inactive.svg";
import CalendarActiveIcon from "../../assets/icons/calendar-active.svg";
import CalendarInactiveIcon from "../../assets/icons/calendar-inactive.svg";
import ProfileActiveIcon from "../../assets/icons/profile-active.svg";
import ProfileInactiveIcon from "../../assets/icons/profile-inactive.svg";

interface NavigationMenuProps {
  className?: string;
  activeTab?: "home" | "appointments" | "profile";
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ className }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeTab = useSelector((state: any) => state.tab.activeTab);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/profile")) {
      dispatch(setActiveTab("profile"));
    } else if (path.includes("/times")) {
      dispatch(setActiveTab("appointments"));
    } else {
      dispatch(setActiveTab("home"));
    }
  }, [location, dispatch]);

  const renderTab = (
    to: string,
    iconActive: string,
    iconInactive: string,
    label: string,
    tabKey: "home" | "appointments" | "profile"
  ) => (
    <Link
      to={to}
      className="text-center"
      onClick={() => dispatch(setActiveTab(tabKey))}
      style={{ cursor: "pointer", textDecoration: "none" }}
    >
      <img
        src={activeTab === tabKey ? iconActive : iconInactive}
        alt={label}
        style={{ width: "24px", height: "24px" }}
      />
      <p
        className="m-0 small"
        style={{
          color: activeTab === tabKey ? "#695CD5" : "#8D8D8D",
        }}
      >
        {label}
      </p>
    </Link>
  );

  return (
    <div
      className={`d-flex justify-content-center align-items-center fixed-bottom py-3 ${className}`}
      style={{
        width: "90%",
        maxWidth: "393px",
        height: "66px",
        backgroundColor: "#FFFFFF",
        borderRadius: "24px",
        margin: `0 auto calc(26px + env(safe-area-inset-bottom)) auto`,
        paddingBottom: "env(safe-area-inset-bottom)",
        boxSizing: "border-box",
      }}
    >
      <div className="d-flex justify-content-around align-items-center w-100 px-4">
        {renderTab("/profile", ProfileActiveIcon, ProfileInactiveIcon, "پروفایل", "profile")}
        {renderTab("/times", CalendarActiveIcon, CalendarInactiveIcon, "نوبت‌ها", "appointments")}
        {renderTab("/main", HomeActiveIcon, HomeInactiveIcon, "خانه", "home")}
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "150%",
          height: "400%",
          background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F1F2F6 100%)",
          backdropFilter: "blur(2px)",
          borderRadius: "24px",
          zIndex: -1,
        }}
      />
    </div>
  );
};

export default NavigationMenu;
