import React from "react";
import { Link } from "react-router-dom";
import { StoreService } from "../../services/store-service";
import "./ServiceItem.css";

interface ServiceItemProps {
  id: number; // اضافه کردن id
  name: string;
  icon: string;
  onClick: () => void; // اضافه کردن onClick
}

const ServiceItem: React.FC<ServiceItemProps> = ({ id, name, icon, onClick }) => {
  // ذخیره انتخاب کاربر در localStorage
  const handleServiceClick = () => {
    StoreService.SetData("selectedService", id);
    onClick(); // اجرای تابع onClick ارسال‌شده
  };

  return (
    <Link
      to={`/times?service=${id}`}
      onClick={handleServiceClick}
      className="service-item-link text-center mx-2"
      aria-label={`سرویس ${name}`}
    >
      <div className="service-item-icon-container">
        <img src={icon} alt={name} className="service-item-icon" />
      </div>
      <p className="service-item-name mt-1">{name}</p>
    </Link>
  );
};

export default ServiceItem;
