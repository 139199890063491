import {GetCurrentLoginInformationsOutput, SessionServiceProxy} from "./shared/service-proxies/service-proxies";
import {AppConsts} from "../core/AppConsts";
import AxiosConfigure from "../core/AxiosConfigure";

export class AuthService{
    _sessionServiceProxy: SessionServiceProxy;
    constructor(token: string | "") {
        if (token) {
            this._sessionServiceProxy = new SessionServiceProxy(AppConsts.appBaseUrl, AxiosConfigure.getInstance());
        }
        else {
            this._sessionServiceProxy = new SessionServiceProxy(AppConsts.appBaseUrl);
        }
    }
    getCurrrentSession():Promise<GetCurrentLoginInformationsOutput> {
        return this._sessionServiceProxy.getCurrentLoginInformations();
    }
}
