import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// تابع کمکی برای به‌روزرسانی رنگ theme-color
const updateThemeColor = (color: string): void => {
    let metaThemeColor = document.querySelector("meta[name=theme-color]") as HTMLMetaElement;

    // اگر تگ theme-color وجود نداشت، آن را ایجاد کن
    if (!metaThemeColor) {
        metaThemeColor = document.createElement('meta');
        metaThemeColor.name = 'theme-color';
        document.head.appendChild(metaThemeColor);
    }

    metaThemeColor.setAttribute("content", color); // تنظیم رنگ جدید
};

const ThemeColorHandler: React.FC = () => {
    const location = useLocation(); // گرفتن مسیر فعلی با استفاده از useLocation

    useEffect(() => {
        // تغییر رنگ theme-color بر اساس مسیر فعلی
        const colorMap: Record<string, string> = {
            "/": "#695CD5",
            "/mobile-verify": "#6A54D0",
            "/verify-otp": "#6A54D0",
            "/main": "#F4F4F4",
            "/times": "#ffffff"
        };

        // از colorMap برای تعیین رنگ استفاده می‌کنیم
        const color = colorMap[location.pathname] ?? "#695CD5"; // رنگ پیش‌فرض
        updateThemeColor(color); // اعمال رنگ
    }, [location]); // اجرا در هر تغییر مسیر

    return null; // این کامپوننت UI ندارد
};

export default ThemeColorHandler;
