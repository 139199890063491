import React, { useEffect, useState, useTransition } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updateUserProfile } from "../../../services/server-actions";
import CalendarModal from "./DatePickerModal";
import styles from "./InfoPage.module.css";
import { DateTime } from "ts-luxon";
import { MobileCustomerProfileDto } from "../../../services/shared/service-proxies/service-proxies";
import { useNavigate } from "react-router-dom";

const ERROR_MESSAGES = {
  firstname: "نام الزامی است",
  lastname: "نام خانوادگی الزامی است",
  sex: "انتخاب جنسیت الزامی است",
  birthday: "تاریخ تولد الزامی است",
};

const schema = Yup.object({
  firstname: Yup.string().required(ERROR_MESSAGES.firstname),
  lastname: Yup.string().required(ERROR_MESSAGES.lastname),
  sex: Yup.number()
    .typeError(ERROR_MESSAGES.sex)
    .required(ERROR_MESSAGES.sex)
    .oneOf([1, 2], "لطفاً جنسیت خود را انتخاب کنید"),
  birthday: Yup.string().required(ERROR_MESSAGES.birthday),
});

interface FormData {
  firstname: string;
  lastname: string;
  sex: number;
  birthday: string;
}

const InfoForm: React.FC = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [storedDate, setStoredDate] = useState<string>(""); // ذخیره تاریخ از localStorage
  const [isPending, startTransition] = useTransition();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedProfile = JSON.parse(localStorage.getItem("user-profile") || "{}");

    if (storedProfile) {
      const { firstname, lastname, sex, birthday } = storedProfile;
      setValue("firstname", firstname || "");
      setValue("lastname", lastname || "");
      setValue("sex", sex || "");

      const formattedDate = birthday
        ? DateTime.fromISO(birthday).toFormat("yyyy-MM-dd")
        : "";
      setValue("birthday", formattedDate);
      setSelectedDate(formattedDate);
      setStoredDate(birthday || ""); // ذخیره تاریخ اصلی
    }
  }, [setValue]);

  const toggleCalendar = () => setCalendarOpen((prev) => !prev);

  const handleDateSelect = (date: string) => {
    setSelectedDate(date);
    setValue("birthday", date); // ذخیره تاریخ در فرم
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    startTransition(() => {
      setErrorMessage(null);
      setIsConfirmed(false);

      // استفاده از تاریخ ذخیره‌شده در صورت عدم تغییر
      const birthday = data.birthday || storedDate;

      // تبدیل تاریخ به DateTime
      const formattedBirthday = DateTime.fromFormat(birthday, "yyyy-MM-dd");
      if (!formattedBirthday.isValid) {
        setErrorMessage("تاریخ انتخابی معتبر نیست.");
        return;
      }

      const updatedProfile = new MobileCustomerProfileDto({
        ...data,
        birthday: DateTime.fromISO(birthday), // مقدار باید به DateTime تبدیل شود
        phone: "",
      });
      
      

      const storedProfile = JSON.parse(localStorage.getItem("user-profile") || "{}");
      const hasChanges = Object.keys(data).some(
        (key) => data[key as keyof FormData] !== storedProfile[key]
      ) || birthday !== storedDate; // بررسی تغییر تاریخ

      if (hasChanges) {
        updateUserProfile(updatedProfile)
          .then(() => {
            setIsConfirmed(true);
            updatedProfile.phone = storedProfile.phone;
            localStorage.setItem("user-profile", JSON.stringify(updatedProfile));
            setTimeout(() => navigate("/profile"), 2000);
          })
          .catch(() => setErrorMessage("❌ خطایی رخ داد. لطفاً دوباره فیلدها را بررسی کنید."));
      } else {
        setErrorMessage("اطلاعات بدون تغییر هستند.");
      }
    });
  };

  return (
    <form className={styles.infoForm} onSubmit={handleSubmit(onSubmit)}>
      <p className={styles.infoText}>جهت تکمیل پروفایل لطفا اطلاعات زیر را وارد کنید</p>
      {["firstname", "lastname"].map((field) => (
        <div className={styles.field} key={field}>
          <input
            type="text"
            placeholder={field === "firstname" ? "نام" : "نام خانوادگی"}
            {...register(field as "firstname" | "lastname")}
            className={styles.input}
          />
          {errors[field as "firstname" | "lastname"] && (
            <span className={styles.error}>{errors[field as "firstname" | "lastname"]?.message}</span>
          )}
        </div>
      ))}
      <div className={styles.field}>
        <select {...register("sex", { valueAsNumber: true })} className={styles.input}>
          <option value="">جنسیت</option>
          <option value={1}>مرد</option>
          <option value={2}>زن</option>
        </select>
        {errors.sex && <span className={styles.error}>{errors.sex.message}</span>}
      </div>
      <div className={styles.field}>
        <input
          type="text"
          placeholder="تاریخ تولد"
          value={selectedDate}
          readOnly
          onClick={toggleCalendar}
          className={styles.input}
        />
        {errors.birthday && <span className={styles.error}>{errors.birthday.message}</span>}
      </div>
      <button
        type="submit"
        className={`${styles.submitButton} ${isConfirmed ? styles.confirmed : ""}`}
        disabled={isPending}
      >
        {isPending ? "در حال ارسال..." : isConfirmed ? "✅ اطلاعات شما ثبت شد" : "ثبت اطلاعات"}
      </button>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      <CalendarModal
        isOpen={isCalendarOpen}
        onClose={toggleCalendar}
        onSelectDate={handleDateSelect}
      />
    </form>
  );
};

export default InfoForm;
