import React, { useEffect, useState } from "react";
import ProfileImage from "./ProfileImage";
import HairdresserCard from "./HairdresserCard";
import styles from "./HairdresserList.module.css";
import { Link } from "react-router-dom";
import { getPartners } from "../../services/server-actions"; // متد برای دریافت `partners`
import { StoreService } from "../../services/store-service"; // وارد کردن کلاس StoreService

interface HairdresserListProps {
  searchQuery: string;
}

const HairdresserList: React.FC<HairdresserListProps> = ({ searchQuery }) => {
  const [hairdressers, setHairdressers] = useState<{ id: number; name: string; logo: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHairdressers = async () => {
      setLoading(true);
      setError(null);

      // ابتدا چک کردن داده‌ها از StoreService
      const storedHairdressers = StoreService.GetData("hairdressers");

      if (storedHairdressers) {
        // اگر داده‌ها موجود بودند، استفاده از آن‌ها
        setHairdressers(storedHairdressers);
        setLoading(false);
      } else {
        try {
          // دریافت داده‌های آرایشگرها از سرور
          const partners = await getPartners();

          // پردازش داده‌ها برای نمایش در UI
          const formattedPartners = partners.map((partner: any) => {
            const logo = partner.profile
              ? partner.profile.startsWith("http")
                ? partner.profile
                : `https://your-cdn-url.com/${partner.profile}` // تبدیل شناسه به URL
              : ""; // اگر تصویر نباشد، مقدار خالی قرار می‌دهیم

            return {
              id: partner.id,
              name: `${partner.firstname ?? ""} ${partner.lastname ?? ""}`.trim() || "نامشخص",
              logo,
            };
          });

          // ذخیره داده‌ها با استفاده از StoreService
          StoreService.SetData("hairdressers", formattedPartners);

          setHairdressers(formattedPartners);
        } catch (err) {
          setError("خطا در دریافت اطلاعات آرایشگرها!");
          console.error("❌ خطا در دریافت داده‌ها:", err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchHairdressers();
  }, []);

  // فیلتر کردن لیست بر اساس جستجو
  const filteredHairdressers = hairdressers.filter(({ name }) =>
    name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link
          to="/DresserList"
          className="btn btn-link text-decoration-none fw-bold p-0"
          style={{ color: "#513DD3" }}
        >
          مشاهده همه
        </Link>
        <h5 className="text-end">آرایشگرها</h5>
      </header>

      {loading ? (
        <p className="text-center"></p>
      ) : error ? (
        <p className="text-center text-danger">{error}</p>
      ) : (
        <div className={`${styles.scrollContainer} ${styles.customScrollbar}`}>
          {filteredHairdressers.map(({ id, logo, name }) => (
            <div key={id} className={styles.card}>
              <ProfileImage image={logo} size={68} partnerId={id} />
              <Link to={`/times?stylist=${name}`} className="text-decoration-none">
                <HairdresserCard
                  title={"آرایشگر"}
                  name={name}
                  availability={"نامشخص"}
                />
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HairdresserList;
