import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/arrowRight.svg"; // ایمپورت آیکون
import styles from "./HeaderTitle.module.css";

interface HeaderTitleProps {
  title?: string; // عنوان هدر (اختیاری، اگر نیازی به نمایش پیش‌فرض نیست)
  showBackButton?: boolean; // آیا دکمه بازگشت نمایش داده شود؟
  onBackNavigateTo?: string; // مسیر برای هدایت پس از کلیک دکمه بازگشت
  children?: React.ReactNode; // امکان جایگذاری محتوای دلخواه در هدر
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  title,
  showBackButton = true,
  onBackNavigateTo = "/profile",
  children,
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBackNavigateTo) {
      navigate(onBackNavigateTo);
    }
  };

  return (
    <div className={styles.header}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {children && <div className={styles.customContent}>{children}</div>}
      {showBackButton && (
        <button className={styles.backButton} onClick={handleBack}>
          <ArrowRightIcon className={styles.icon} />
        </button>
      )}
    </div>
  );
};

export default HeaderTitle;
