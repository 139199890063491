import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import NavigationSection from "../components/NavigationMenu/NavigationMenu";
import AvilableServices from "../components/Services/AvilableServices";
import DateNavigation from "../components/TopSection/DateNavigation";
import FilterSection from "../components/Services/FilterSection";
import styles from "./TimesPage.module.css";
import { utils, Day } from "react-modern-calendar-datepicker";
import { StoreService } from "../services/store-service";
import { getTimesheet } from "../services/server-actions";
import { ReactComponent as RedEllipse } from "../../src/assets/icons/RedEllipse.svg";
import { ReactComponent as SortIcon } from "../../src/assets/icons/Sort.svg";

const TimesPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const categoryIdParam = searchParams.get("service");
  const partnerIdParam = searchParams.get("stylist");

  const [filteredCategory, setFilteredCategory] = useState<number | null>(
    categoryIdParam ? parseInt(categoryIdParam) : null
  );
  const [filteredPartner, setFilteredPartner] = useState<number | null>(
    partnerIdParam ? parseInt(partnerIdParam) : null
  );
  const [selectedDateISO, setSelectedDateISO] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [timesheet, setTimesheet] = useState<any[]>([]);
  const [timesheetLoading, setTimesheetLoading] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const categories = useMemo(() => {
    const storedCategories = StoreService.GetData("categories") || [];
    return storedCategories;
  }, []);

  const partners = useMemo(() => {
    const storedPartners = StoreService.GetData("partners") || [];
    return storedPartners.map((partner: any) => ({
      id: partner.id,
      name: `${partner.firstname} ${partner.lastname}`,
    }));
  }, []);

  const fetchTimesheet = useCallback(
    async (dateISO: string, categoryId: number | null, partnerId: number | null) => {
      try {
        setTimesheetLoading(true);
        const timesheetData = await getTimesheet(dateISO, categoryId ?? undefined, partnerId ?? undefined);
        setTimesheet(timesheetData);
      } catch (error) {
        console.error("❌ خطا در دریافت تایم‌شیت:", error);
      } finally {
        setTimesheetLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    // اعمال categoryIdParam از URL به فیلتر
    if (categoryIdParam) {
      setFilteredCategory(parseInt(categoryIdParam));
    }
    if (partnerIdParam) {
      setFilteredPartner(parseInt(partnerIdParam));
    }
  }, [categoryIdParam, partnerIdParam]);

  useEffect(() => {
    fetchTimesheet(selectedDateISO, filteredCategory, filteredPartner);
  }, [selectedDateISO, filteredCategory, filteredPartner, fetchTimesheet]);

  const handleFilterChange = useCallback(
    (categoryId: number | null, partnerId: number | null) => {
      setFilteredCategory(categoryId);
      setFilteredPartner(partnerId);

      categoryId !== null
        ? StoreService.SetData("categoryId", categoryId)
        : localStorage.removeItem("categoryId");

      partnerId !== null
        ? StoreService.SetData("partnerId", partnerId)
        : localStorage.removeItem("partnerId");
    },
    []
  );

  const handleDateChange = useCallback((date: Day) => {
    const dateISO = new Date(date.year, date.month - 1, date.day).toISOString().split("T")[0];
    setSelectedDateISO(dateISO);
  }, []);

  const toggleFilter = useCallback(() => setIsFilterOpen((prev) => !prev), []);

  // محاسبه وضعیت وجود داده‌ها
  const hasReservations = useMemo(() => {
    return timesheet.length > 0;
  }, [timesheet]);

  return (
    <Layout>
      <div>
        {/* ناوبری تاریخ */}
        <div className={styles.dateNavigation}>
          <DateNavigation onDateChange={handleDateChange} />
        </div>

        {/* بخش فیلتر */}
        <section className={styles.timesPageSection}>
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <button
              onClick={toggleFilter}
              className={`${styles.filterButton} d-flex align-items-center justify-content-center`}
            >
              <span className={styles.filterButtonText}>
                فیلتر
                <SortIcon className={styles.sortIcon} />
                {filteredCategory !== null || filteredPartner !== null ? (
                  <RedEllipse className={styles.redCircle} />
                ) : null}
              </span>
            </button>

            <h6 className="fw-bold" style={{ marginRight: "24px" }}>
              وقت‌های {utils("fa").getToday().day} {utils("fa").getMonthName(utils("fa").getToday().month)}{" "}
              {utils("fa").getToday().year}
            </h6>
          </div>

          {/* فرم فیلتر */}
          {isFilterOpen && (
            <div className="mb-2">
              <FilterSection
                onFilterChange={handleFilterChange}
                services={categories}
                partners={partners}
                selectedService={filteredCategory?.toString() ?? null}
                selectedPartner={filteredPartner?.toString() ?? null}
                selectedDateISO={selectedDateISO}
                hasReservations={hasReservations} // ارسال وضعیت وجود داده‌ها
              />
            </div>
          )}

          {/* نمایش تایم‌شیت */}
          <div className="d-flex justify-content-center">
            {timesheetLoading ? (
              <p className="text-center"></p>
            ) : hasReservations ? (
              <AvilableServices
                reservations={timesheet}
                selectedCategoryId={filteredCategory}
                selectedPartnerId={filteredPartner}
                layout="vertical"
              />
            ) : (
              <p className="text-center">داده‌ای برای این روز وجود ندارد. لطفاً فیلترها را تغییر دهید.</p>
            )}
          </div>
        </section>

        {/* ناوبری */}
        <div className="mb-2">
          <NavigationSection />
        </div>
      </div>
    </Layout>
  );
};

export default TimesPage;
