export class StoreService {
    static GetData<T = any>(key: string): T | null {
        const json = localStorage.getItem(key);
        if (!json) return null;
  
        // بررسی اینکه آیا مقدار JSON است یا یک رشته ساده
        if (json.startsWith("{") || json.startsWith("[")) {
            try {
                return JSON.parse(json); // ✅ اگر مقدار JSON باشد، آن را تبدیل کن
            } catch (error) {
                console.warn(`⚠️ مقدار ${key} در localStorage یک JSON معتبر نیست:`, json);
            }
        }
  
        return json as unknown as T; // اگر JSON نبود، مقدار خام را برگردان
    }
  
    static SetData(key: string, data: any): void {
        try {
            if (typeof data === "string") {
                localStorage.setItem(key, data); // ذخیره مستقیم رشته
            } else {
                localStorage.setItem(key, JSON.stringify(data)); // ذخیره به صورت JSON
            }
        } catch (error) {
            console.error(`❌ خطا در ذخیره ${key} در localStorage:`, error);
        }
    }
  }
  