import React from "react";
import HeaderTitle from "../components/Profile/Header/HeaderTitle";
import InfoForm from "../components/Profile/info/InfoForm";
import Layout from "../components/Layout/Layout";

const InfoPage: React.FC = () => {
  return (
    <Layout>
      <HeaderTitle title="اطلاعات شخصی" showBackButton={true} />
      <InfoForm />
    </Layout>
  );
};

export default InfoPage;
